<template>
	<v-app>
		<v-main>
			<router-view />
		</v-main>
	</v-app>
</template>

<script>
	export default {
		name: 'App',
		created() {
			// Todas rotas serão monitoradas
			this.$gtag.pageview(this.$route);
		},
		watch: {
		'$route':{
			handler: (to) => {
				document.title = to.meta.title || 'Hitbel Metadados'
			},
				immediate: true
			}
		}
	};
</script>