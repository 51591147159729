<template>
    <div>
        <app-list-users></app-list-users>
    </div>
</template>

<script>
import ListUsers from '../components/ListUsers'

export default {
    components: {
        'app-list-users': ListUsers
    }
}
</script>

<style>

</style>