<template>
    <v-form 
        ref="form"
        v-model="hableSubmit"
    >
        <div class="bg-card-content">
            <app-loading-full :active="loading"></app-loading-full>
            <h3 class="white--text mb-8 text-uppercase">INFORMAÇÕES BÁSICAS</h3>
            <div>
                <v-row>
                    <v-col cols="12" lg="6">
                        <v-text-field
                            v-model="no_title"
                            :disabled="'disabled'"
                            label="Título"
                            required
                            :rules="[rules.required]"
                            dark
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-select
                            label="Tipo"
                            :items="ds_track_version_type"
                            :disabled="'disabled'"
                            item-text="ds_track_version_type"
                            item-value="ds_track_version_type"
                            v-model="ds_track_version_type"
                            :rules="[rules.required]"
                            required
                            dark
                        ></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox
                            v-model="hasMultipleArtists"
                            :disabled="'disabled'"
                            label="Essa música tem a participação de múltiplos artistas"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <div v-if="hasMultipleArtists">
                    <h4 class="white--text mb-4">Participação</h4>
                    <v-row v-for="(participation, index) in participations" :key="index">
                        <v-col cols="12" lg="6">
                            <v-select
                                label="Tipo de participação"
                                :disabled="'disabled'"
                                :items="participationType"
                                item-text="desc"
                                item-value="code"
                                v-model="participation.type"
                                :rules="[rules.required]"
                                required
                                dark
                            ></v-select>
                        </v-col>
                        <v-col cols="12" lg="6">
                                <v-text-field
                                label="Artista"
                                v-model="participation.artist"
                                :rules="[rules.required]"
                                :disabled="'disabled'"
                                required
                                dark
                                :append-icon="participations.length > 1 ? 'mdi-minus-circle' : ''"
                                @click:append="participations.splice(index, 1)"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                </div>
            </div>
        </div>
        <div class="bg-card-content">
            <h3 class="white--text mb-8 text-uppercase">GÊNERO</h3>
            <div>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-select
                            dark
                            :rules="[rules.required]"
                            :disabled="'disabled'"
                            v-model="no_genre"
                            :items="no_genre"
                            item-text="no_genre"
                            item-value="no_genre"
                            label="Gênero Principal"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                            dark
                            :rules="[rules.required]"
                            :disabled="'disabled'"
                            v-model="sub_genre"
                            :items="sub_genre"
                            item-text="sub_genre"
                            item-value="sub_genre"
                            label="Gênero Secundário"
                        ></v-select>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div class="bg-card-content">
            <h3 class="white--text mb-8 text-uppercase">INFORMAÇÕES AVANÇADAS</h3>
            <div>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-select
                            dark
                            :rules="[rules.required]"
                            :disabled="'disabled'"
                            v-model="no_language"
                            :items="no_language"
                            item-text="no_language"
                            item-value="no_language"
                            label="Idioma da música"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="ISRC"
                            :rules="[rules.required]"
                            :disabled="'disabled'"
                            required
                            dark
                            v-model="co_isrc"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="8">
                        <v-textarea
                            :rules="[rules.required]"
                            :disabled="'disabled'"
                            v-model="ds_lyric"
                            label="Letra da música"
                            auto-grow
                            dark
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-checkbox
                            v-model="is_instrumental"
                            :disabled="'disabled'"
                            label="Instrumental"
                        ></v-checkbox>
                        <v-checkbox
                            v-model="is_explicit"
                            :disabled="'disabled'"
                            label="Conteúdo Explícito"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div class="bg-card-content">
            <h3 class="white--text mb-8 text-uppercase">CRÉDITOS DA MÚSICA</h3>
            <div>
                <v-row>
                    <v-col cols="12" lg="4">
                        <div >
                            <v-text-field
                                label="Produtor"
                                v-model="no_producer"
                                :disabled="'disabled'"
                                required
                                :rules="[rules.required]"
                                dark
                                
                            ></v-text-field>
                        </div>
                        <!-- <div class="d-flex align-center pointer" @click="credits.producers.push('')">
                            <v-icon class="mr-3">
                                mdi-plus-circle
                            </v-icon>
                            <span class="white--text">Adicionar produtor</span>
                        </div> -->
                    </v-col>
                    <v-col cols="12" lg="8">
                        <v-row>
                            <v-col cols="12" lg="6">
                                <v-text-field
                                    
                                    label="Compositor"
                                    v-model="no_composer"
                                    :disabled="'disabled'"
                                    required
                                    :rules="[rules.required]"
                                    dark
                                   
                                   
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <v-text-field
                                    
                                    label="Editora"
                                    v-model="ds_publisher"
                                    :disabled="'disabled'"
                                    required
                                    :rules="[rules.required]"
                                    dark
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <!-- <div >
                            <v-icon class="mr-3">
                                mdi-plus-circle
                            </v-icon>
                            <span class="white--text">Adicionar compositor e editora</span>
                        </div> -->
                    </v-col>
                </v-row>
            </div>
        </div>
        <div class="mt-12 d-flex justify-end">
            <v-btn
                class="mr-4 clear-button button-clear-size"
                outlined
                dark
                color="white"
                height="40"
                @click="$emit('decrement');"
            >
                <span>Voltar</span>
            </v-btn>
            <v-btn
                :disabled="!hableSubmit"
                class="submit-button"
                height="40"
                @click="sendInfo()"
            >
                <span>Próximo</span>
            </v-btn>
        </div>
    </v-form>
</template>

<script>

// import CardUser from './CardUser.vue'
// import Notification from './Notification.vue';
import LoadingFull from '../components/LoadingFull.vue';
import { getTrackByAlbum } from "../services/Track";
// import EditLabel from './EditLabel.vue'

export default {
    components: {
        // 'app-card-user': CardUser
        // 'app-notification': Notification,
        'app-loading-full': LoadingFull,
        // 'app-label-edit': EditLabel
    },
    props: {
        idLabel: {
            required: false,
            type: String
        }
    },
    created() {
        this.profileName = this.$route.params.name;
        this.idAlbum = parseInt(this.$route.params.idAlb);
        this.getTracksAlbums(this.idAlbum);
    },
    data: () => ({
        select: [],
        hableSubmit: false,
        trackTitle: '',
        trackTypeList: [],
        trackTypeSelected: '',
        hasMultipleArtists: false,
        participations: [],
        participationType: [{code: 8, desc: 'Feat.'}, {code: 5, desc: 'Colab.'}],
        credits: {
            producers: [],
            composers: [],
        },
        primaryGenres: [],
        secondaryGenres: [],
        mainGenre: '',
        secondaryGenre: '',
        language: '',
        languages: [],
        instrumental: false,
        explicit: false,
        isrc: '',
        lyrics: '',
        rules: {
            required: value => !!value || 'Este campo é obrigatório.'
        },



        notification: {
            active: false,
            message: '',
            type: ''
        },
        loading: false,
        no_label: '',
        no_genre: '',
        co_upc: '',
        ds_copyright_recording: '',
        ds_copyright_release: '',
        dt_release: '',
        sub_genre: '',
        no_language: '',
        no_title: '',
        type: '',
        im_artwork: '',
        no_producer: '',
        no_composer: '',
        ds_publisher: '',
        ds_audiotrack: '',
    }),
    methods: {
        getTracksAlbums(id) {
            this.loading = true;
            // const encodeId = btoa(JSON.stringify({"album":{"id_album": 1229}}));
            const albumResponse = getTrackByAlbum(id);
            albumResponse
            .then(res => {
                const { data } = res.data;
                this.no_title = data[0].tracks[0].no_title;
                this.ds_track_version_type = data[0].tracks[0].version_type.ds_track_version_type;
                this.no_genre = data[0].tracks[0].genre.no_genre;
                this.co_upc = data[0].co_upc;
                this.sub_genre = data[0].tracks[0].sub_genre.no_genre;
                this.no_language = data[0].tracks[0].language.no_language;
                this.co_isrc = data[0].tracks[0].recording.co_isrc;
                this.ds_lyric = data[0].tracks[0].recording.composition.ds_lyric;
                this.is_instrumental = data[0].tracks[0].is_instrumental;
                this.is_explicit = data[0].tracks[0].is_explicit;
                this.no_producer = data[0].tracks[0].members[0].album_member.artist_member.no_member;
                this.no_composer = data[0].tracks[0].members[1].album_member.artist_member.no_member;
                this.ds_publisher = data[0].tracks[0].members[1].album_member.ds_publisher;
                // console.log(this.no_composer)
                this.ds_audiotrack = data[0].tracks[0].ds_audiotrack;
                // console.log("sdsfsfasfasfgadgag")
                // console.log(this.ds_audiotrack)
                // this.type = data[0].type.ds_type;
                // this.im_artwork = data[0].im_artwork;
                    
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            }) 
        },
        setTrackInfo () {
            const musics = {
                producerName: this.no_producer,
                composerName: this.no_composer,
                publisherName: this.ds_publisher,
                faixa: this.no_title,
                isrc: this.co_isrc,
                audio: this.ds_audiotrack,
                // faixa: this.no_title,
            }

            // const genre = this.primaryGenres.filter( genre => genre.id_genre == this.mainGenre);
            // albumInfoDetails.mainGenreName = this.no_genre;

            // const albumType = this.albumTypeList.filter( albumTypeItem => albumTypeItem.id_album_type == this.albumTypeSelect);
            // albumInfoDetails.albumTypeName = this.type;

            // if (albumInfoDetails.albumTypeName == 'SINGLE') {
            //     albumInfoDetails.albumMembers = this.participations
            // }

            return musics
        },
        
        sendInfo() {
            // console.log(this.ds_audiotrack)
            this.$emit("musics", this.setTrackInfo());
            this.$emit("increment");
        },
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .bg-table {
        background-color: #071c33 !important;
    }

    tbody {
        tr:hover {
            background-color: #041527 !important;
        }
    }
    .submit-button {
        width: 150px !important;
    }
</style>