<template>
    <div>
        <app-loading-full :active="loading"></app-loading-full>
        <h3 class="white--text mt-6">ARTISTAS CADASTRADOS</h3>
        <!-- <div class="d-flex flex-wrap mt-3">
            <div v-for="label in labels" :key="label.id_label">
                <app-card-user :userName="label.no_label" class="mr-6 mb-6" :description="label.qt_artists + ' Artistas'" :avatarUrl="label.avatar_irl"></app-card-user>
            </div>
        </div> -->
        <v-card dark class="mt-4" color="#071c33">
            <v-col md="6" class="mb-4">
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar Artista"
                single-line
                hide-details
                ></v-text-field>
            </v-col>
            <v-data-table
                :loading="loading" loading-text="Carregando..."
                :headers="headers"
                :items="desserts"
                :search="search"
                item-key="id_artist"
                class="elevation-1 bg-table"
            >
                <template v-slot:item.actions="{ item }">
                    <router-link :to="'/artista/'+ item.no_artistic_name + '/'+ item.id_artist" class="text-decoration-none">
                        <v-icon size="16">mdi-eye</v-icon>
                    </router-link>
                </template>
            </v-data-table>
        </v-card>
        <app-notification 
            @notificationStatus="changeNotificationStatus" 
            :active="notification.active"
            :type="notification.type"
            :message="notification.message"    
        >
        </app-notification>
        <div class="mt-6 d-flex justify-end flex-wrap-reverse">
                <v-btn
                    class="mr-4 clear-button button-clear-size"
                    outlined
                    dark
                    color="white"
                    height="40"
                    @click="$router.go(-1)"
                >
                    <span>Voltar</span>
                </v-btn>
        </div>
    </div>
</template>

<script>

// import CardUser from './CardUser.vue'
import { getArtists, getArtistsByLabel } from '../services/Artist'
import Notification from './Notification.vue';
import LoadingFull from '../components/LoadingFull.vue';
// import EditLabel from './EditLabel.vue'

export default {
    components: {
        // 'app-card-user': CardUser
        'app-notification': Notification,
        'app-loading-full': LoadingFull,
        // 'app-label-edit': EditLabel
    },
    props: {
        idLabel: {
            required: false,
            type: String
        }
    },
    created() {
        if (this.idLabel) {
            this.getArtistsLabel(this.idLabel);
        } else {
            this.getArtistsLabel(btoa(JSON.stringify({"id_label": parseInt(localStorage.id_profile_entity)})));
        }
    },
    data: () => ({
        notification: {
            active: false,
            message: '',
            type: ''
        },
        artists: [],
        loading: false,
        headers:  [
          { text: 'Nome do Artista', value: 'no_artistic_name', sortable: true, filterable: true },
          { text: 'Quantidade de Álbuns', align: 'center', value: 'qt_albuns', sortable: true},
          { text: 'Selo', align: 'center', value: 'label.no_label', sortable: true},
          { text: 'Ações', align: 'center', value: 'actions', sortable: false, filterable: false },
        ],
        desserts: [],
        search: ''
    }),
    methods: {
        getArtists() {
            this.loading = true;
            const artistsResponse = getArtists();
            artistsResponse
            .then(res => {
                const { data } = res.data;
                this.desserts = data;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            })
        },
        getArtistsLabel(label) {
            this.loading = true;
            const artistsResponse = getArtistsByLabel(label);
            artistsResponse
            .then(res => {
                const { data } = res.data;
                this.desserts = data;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            })
        },
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .bg-table {
        background-color: #071c33 !important;
    }

    tbody {
        tr:hover {
            background-color: #041527 !important;
        }
    }
</style>