<template>
    <div>
        <app-loading-full :active="loading"></app-loading-full>
        <app-profile-header :profileName="profileName" :profileType="profileType" :options="true" :idArtist="idArtist"></app-profile-header>
        <div class="d-flex flex-wrap mt-8 justify-space-around">
            <div v-for="(album, index) in albums" :key="index">
                <app-card-album :albumCover="album.im_artwork" :albumName="album.no_title" :albumType="album.type.ds_type"></app-card-album>
            </div>
        </div>
        <div class="mt-6 d-flex justify-end flex-wrap-reverse">
                <v-btn
                    class="mr-4 clear-button button-clear-size"
                    outlined
                    dark
                    color="white"
                    height="40"
                    @click="$router.go(-1)"
                >
                    <span>Voltar</span>
                </v-btn>
        </div>
    </div>
</template>

<script>
import LoadingFull from '../components/LoadingFull.vue';
import ProfileHeader from "../components/ProfileHeader";
import CardAlbum from "../components/CardAlbum";
import { getAlbumByArtist } from "../services/Album";

export default {
    components: {
        'app-profile-header': ProfileHeader,
        'app-loading-full': LoadingFull,
        'app-card-album': CardAlbum
    },
    created() {
        this.profileName = localStorage.tp_profile == 'Artist' ? localStorage.no_profile : this.$route.params.name;
        this.idArtist = parseInt(this.$route.params.id);
        this.getArtisAlbums(this.idArtist);
    },
    data: () => ({
        profileType: 'Artista',
        profileName: '',
        loading: false,
        albums: []
    }),
    methods: {
        getArtisAlbums(id) {
            this.loading = true;
            const encodeId = btoa(JSON.stringify({"artist":{"id_artist": id}}));
            const albumResponse = getAlbumByArtist(encodeId);
            albumResponse
            .then(res => {
                const { data } = res.data;
                this.albums = data;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            }) 
        }
    }
}
</script>