<template>
    <v-form
        ref="form"
        v-model="hableSubmit"
    >
        <div class="bg-card-content" v-if="typeUser != 'Artist'">
            <h3 class="white--text mb-8 text-uppercase">Seleção de artista</h3>
            <v-row>
                <v-col v-if="typeUser == 'Client'">
                    <v-autocomplete
                        :disabled="loading"
                        dark
                        v-model="labelSelected"
                        :items="labels"
                        item-text="no_label"
                        item-value="id_label"
                        :rules="[rules.required]"
                        label="Selecione o selo que o artista pertence"
                        @change="getArtists()"
                    ></v-autocomplete>
                    <router-link :to="'/cadastro-usuario'" class="text-decoration-none">
                        <v-icon size="16">mdi-plus</v-icon>
                    </router-link>
                </v-col>
                <v-col v-if="labelSelected">
                    <v-autocomplete
                        :disabled="loading"
                        dark
                        v-model="artistSelected"
                        :items="artists"
                        item-text="no_artist"
                        item-value="id_artist"
                        :rules="[rules.required]"
                        label="Selecione o artista que você deseja cadastrar o álbum"
                        @change="getArtistData()"
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </div>
        <template>
            <div class="bg-card-content">
                <h3 class="white--text mb-8 text-uppercase">Informações básicas</h3>
                <div>
                    <v-row>
                        <v-col cols="12" md="8" lg="6">
                            <img src="../assets/album-null.png" alt="" width="100%" v-if="cover.length < 1" class="disc-cover-none"/>
                            <div v-else>
                                <img :src="getCoverUrl()" class="relative disc-cover">
                                <img src="../assets/disc.png" alt="" class="disc d-none d-md-inline">
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6" md="4">
                            <h4 class="white--text mb-2">Requerimentos para a arte de capa</h4>
                            <ul>
                                <li>Formato do arquivo: JPEG ou PNG</li>
                                <li>Tamanho: pelo menos 600x600 pixels</li>
                                <li>Modo de cor: RGB</li>
                                <li>Sua arte não pode conter logos</li>
                                <li>Sua arte não pode conter endereço de sites</li>
                                <li>Sua arte não pode conter datas ou anúncios</li>
                            </ul>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-file-input
                                accept="image/jpeg,image/x-png,image/png"
                                placeholder="Adicionar imagem de capa"
                                prepend-icon="mdi-camera"
                                label="ADICIONAR CAPA"
                                v-model="cover"
                                :rules="[rules.required]"
                                @change="setCoverPreview()"
                                :clearable="false"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select
                                label="Tipo"
                                :items="albumTypeList"
                                v-model="albumTypeSelect"
                                item-text="ds_type"
                                item-value="id_album_type"
                                :rules="[rules.required]"
                                required
                                dark
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="albumTitle"
                                label="Título"
                                :rules="[rules.required]"
                                required
                                dark
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="albumTypeSelect == 2">
                                <v-checkbox
                                v-model="hasMultipleArtists"
                                label="Esse produto tem a participação de múltiplos artistas"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <div v-if="hasMultipleArtists">
                        <h4 class="white--text mb-4">Participação</h4>
                        <v-row v-for="(participation, index) in participations" :key="index">
                            <v-col cols="12" lg="6">
                                <v-select
                                    label="Tipo de participação"
                                    :items="participationType"
                                    item-text="desc"
                                    item-value="code"
                                    :rules="[rules.required]"
                                    v-model="participation.type"
                                    required
                                    dark
                                ></v-select>
                            </v-col>
                            <v-col cols="12" lg="6">
                                    <v-text-field
                                    label="Artista"
                                    :rules="[rules.required]"
                                    v-model="participation.artist"
                                    required
                                    dark
                                    :append-icon="participations.length > 1 ? 'mdi-minus-circle' : ''"
                                    @click:append="participations.splice(index, 1)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-col cols="12">
                            <div class="d-flex align-center pointer" @click="participations.push({type: '', artist: ''})">
                                <v-icon class="mr-3">
                                    mdi-plus-circle
                                </v-icon>
                                <span class="white--text">Adicionar artista</span>
                            </div>
                        </v-col>
                    </div>
                </div>
            </div>
            <div class="bg-card-content">
                <h3 class="white--text mb-8 text-uppercase">Gênero e Idiomas</h3>
                <div>
                    <v-row>
                        <v-col cols="12" lg="4">
                        <v-select
                            dark
                            v-model="mainGenre"
                            :items="primaryGenres"
                            item-text="no_genre"
                            item-value="id_genre"
                            label="Gênero Principal"
                        ></v-select>
                        </v-col>
                        <v-col cols="12" lg="4">
                            <v-select
                                dark
                                v-model="secondaryGenre"
                                :items="secondaryGenres"
                                item-text="no_genre"
                                item-value="id_genre"
                                label="Gênero Secundário"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" lg="4">
                            <v-select
                                dark
                                v-model="language"
                                :items="languages"
                                item-text="no_language"
                                item-value="id_language"
                                label="Idioma Principal"
                                :rules="[rules.required]"
                            ></v-select>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div class="bg-card-content">
                <h3 class="white--text mb-8 text-uppercase">Lançamentos e datas</h3>
                <v-row>
                    <v-col cols="12">
                            <v-checkbox
                            v-model="migration"
                            label="Esse produto é uma migração"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <div>
                    <v-row>
                        <v-col cols="12" md="6" lg="3">
                            <v-menu
                                v-model="releaseDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="releaseDate"
                                        :label="migration ? 'Data de Lançamento Original*' : 'Data de Lançamento*'"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="releaseDate"
                                    @input="releaseDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="6" lg="3" v-if="migration">
                            <v-menu
                                v-model="migrationDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="migrationDate"
                                        label="Data de Migração"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="migrationDate"
                                    @input="migrationDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <v-menu
                                v-model="productionDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="productionDate"
                                        label="Copyright e Data de Produção"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="productionDate"
                                    @input="productionDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <v-menu
                                v-model="recordingDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="recordingDate"
                                        label="Copyright e Data de Gravação"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="recordingDate"
                                    @input="recordingDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div class="bg-card-content">
                <h3 class="white--text mb-8 text-uppercase">Código UPC</h3>
                <div>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Código de Barras (UPC)"
                                :rules="[rules.required]"
                                dark
                                v-model="upc"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div class="mt-12 d-flex justify-end">
                <v-btn
                    class="mr-4 clear-button button-clear-size"
                    outlined
                    dark
                    color="white"
                    height="40"
                    @click="$router.go(-1)"
                >
                    <span>Voltar</span>
                </v-btn>
                 <v-btn
                    :disabled="!hableSubmit"
                    class="submit-button"
                    height="40"
                    @click="sendInfo()"
                >
                    <span v-if="loading">Salvando</span>
                    <span v-else>Salvar e Continuar</span>
                </v-btn>
            </div>
        </template>
    </v-form>
</template>

<script>
import { getAlbumType } from '../services/Album.js';
import { getGenre } from '../services/Genre';
import { getLanguage } from '../services/Location';
import { getLabels } from '../services/Label';
import { getArtistById, getArtistsByLabel } from '../services/Artist';
import moment from 'moment'

export default {
    created() {
        this.getAlbumType();
        this.getGenreList();
        this.getLanguageList();
        if (this.typeUser == 'Client') {
            this.getLabels();
        } else if (this.typeUser == 'Label') {
            this.labelSelected = btoa(JSON.stringify({"id_label": parseInt(localStorage.id_profile_entity)}));
            this.getArtists();
        } else {
            this.artistSelected = parseInt(localStorage.id_profile_entity);
            this.getArtistData();
        }
    },

    data: () => ({
        select: [],
        albumTypeList: [],
        hasMultipleArtists: false,
        hableSubmit: true,
        albumTitle: '',
        typeUser: localStorage.tp_profile,
        migration: false,
        cover: [],
        albumTypeSelect: '',
        coverImg: '',
        participations: [{type: '', artist: ''}],
        participationType: [{code: 8, desc: 'Feat.'}, {code: 5, desc: 'Collab.'}],
        primaryGenres: [],
        secondaryGenres: [],
        mainGenre: '',
        secondaryGenre: '',
        language: '',
        languages: [],
        releaseDateMenu: false,
        releaseDate: '',
        migrationDateMenu: false,
        migrationDate: '', 
        productionDateMenu: false,
        productionDate: '',
        recordingDateMenu: false,
        recordingDate: '', 
        upc: '',
        labels: [],
        labelName: '',
        labelSelected: '',
        artists: [],
        artistSelected: null,
        artistName: '',
        loading: false,
        rules: {
            required: value => !!value || 'Este campo é obrigatório.'
        },
    }),
    methods: {
        setAlbumInfo() {
            const albumData = {
                "no_title": this.albumTitle,
                "type": {
                    "id_album_type": this.albumTypeSelect
                },
                "language": {
                    "id_language": this.language
                },
                "artist": {
                    "id_artist": this.artistSelected
                },
                 "genre": {
                    "id_genre": this.mainGenre
                },
                "sub_genre": {
                    "id_genre": this.secondaryGenre
                },
                "label": JSON.parse(atob(this.labelSelected)),
                "volumes": [
                    {
                        "no_album_volume": this.albumTitle,
                        "nu_order_volume": 1
                    }
                ],
                "territories": [{
                    "id_territory": 52
                }],
                "dt_release": moment(this.releaseDate).format('YYYY-MM-DDT00:00:00.000') + 'Z',
                "ds_copyright_release": "",
                "ds_copyright_recording": "",
                "co_upc": this.upc
            };
            if (this.migrationDate) {
                albumData.dt_original_release = moment(this.migrationDate).format('YYYY-MM-DDT00:00:00.000') + 'Z';
            } else {
                albumData.dt_original_release = albumData.dt_release;
            }

            return albumData
        },
        setAlbumInfoDetails () {
            const albumInfoDetails = {
                labelName: this.labelName,
                artistName: this.artistName,
                albumTypeName: '',
                mainGenreName: '',
                upcAlbum: this.upc,
                albumMembers: []
            }

            const genre = this.primaryGenres.filter( genre => genre.id_genre == this.mainGenre);
            albumInfoDetails.mainGenreName = genre[0].no_genre;

            const albumType = this.albumTypeList.filter( albumTypeItem => albumTypeItem.id_album_type == this.albumTypeSelect);
            albumInfoDetails.albumTypeName = albumType[0].ds_type;

            if (albumInfoDetails.albumTypeName == 'SINGLE') {
                albumInfoDetails.albumMembers = this.participations
            }

            return albumInfoDetails
        },
        sendInfo() {
            this.$emit("album", this.setAlbumInfo());
            this.$emit("albumDetails", this.setAlbumInfoDetails())
            this.$emit("albumCover", this.coverImg);
            this.$emit("increment");
        },
        getAlbumType() {
            const reqAlbumType = getAlbumType();
            reqAlbumType
            .then(res => {
                this.albumTypeList = res.data.data;
            })
            .catch(err => {
                console.log(err);
            })
        },
        setCoverPreview() {
            const coverReader = new FileReader();   
            coverReader.onloadend = () => {
                this.coverImg = coverReader.result;
            }
            if (["image/jpeg", "image/x-png", "image/png"].includes(this.cover.type)) {
                coverReader.readAsDataURL(this.cover);   
            } else {
                this.removeCover();
            }
        },
        removeCover() {
            this.cover = [];
            this.coverImg = '';
        },
        getCoverUrl() {
            return this.coverImg;
        },
        getGenreList() {
            const genreResponse = getGenre();
            genreResponse
            .then(res => {
                const { data } = res.data;
                this.primaryGenres = data.filter( genre => genre.is_subgenre == false);
                this.secondaryGenres = data.filter( genre => genre.is_subgenre == true);
            })
            .catch(err => {
                console.log(err);
            })
        },
        getLanguageList() {
            const languageResponse = getLanguage();
            languageResponse
            .then(res => {
                const { data } = res.data;
                this.languages = data.map( language => ({
                    "id_language": language.id_language,
                    "no_language": language.no_language
                }))
            })
            .catch(err => {
                console.log(err);
            })
        },
        getLabels() {
            this.loading = true;
            const labelResponse = getLabels();
            labelResponse
            .then(res => {
                const { data } = res.data;
                this.labels = data.map( label => ({
                    "id_label": btoa(JSON.stringify({"id_label": parseInt(label.id_label)})),
                    "no_label": label.no_label
                }))
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            })
        },
        getArtists() {
            this.loading = true;
            const artistsResponse = getArtistsByLabel(this.labelSelected);
            artistsResponse
            .then(res => {
                const { data } = res.data;
                this.artists = data.map( artist => ({
                    "id_artist": artist.id_artist,
                    "no_artist": artist.no_artistic_name
                }))
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            })
        },
        getArtistData() {
            this.loading = true;
            const artistResponse = getArtistById(this.artistSelected);
            artistResponse
            .then(res => {
                const { data } = res.data;    
                this.labelName = data.label ? data.label.no_label : 'Artista independente';
                this.artistName = data.no_artistic_name;
                this.labelSelected = btoa(JSON.stringify({"id_label": parseInt(data.id_label)}));

                if (data.params) {
                    const params = JSON.parse(data.params);
                    this.mainGenre = params.mainGenre;
                    this.secondaryGenre = params.secondaryGenre;
                    this.language = params.language;
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            })
        }
    }
}
</script>

<style scoped lang="scss">
    .bg-card-content {
        background-color: rgb(0 0 0 / 41%);
        padding: 2rem;
        margin-bottom: 30px;
        height: auto;
    }
    ul {
        padding-left: 14px;

        li {
            font-size: 0.875rem;
            color: white;
        }
    }
    .fluid-div {
        width: 100%;
    }
    .pointer {
        cursor: pointer;
    }
    .cover-button {
        width: 70%;
        min-width: 158px !important;
        border-radius: 100px !important;
    }
    .relative {
        position: relative;
    }
    .disc {
        position: absolute;
        height: 226px;
    }
    .disc-cover-none {
        max-width: 337px;
        max-height: 225px;
    }
    .disc-cover {
        width: 230px;
        height: 225px;
    }
    .submit-button {
        max-width: 250px !important;
    }
</style>