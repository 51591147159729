<template>
    <div>
        <h3 class="white--text text-center">PUBLICAÇÃO DE ÁLBUM</h3>
        <v-stepper id="stepper" alt-labels dark class="mt-12" tile v-model="e1">
            <v-stepper-header class="elevation-0">
                <!-- <v-stepper-step step="0" :complete="e1 > 0" color="#eb6573" class="config-teste">
                    <span class="text-center">Selecionar Artista</span>
                </v-stepper-step> -->

                <!-- <v-divider></v-divider> -->

                <v-stepper-step step="1" :complete="e1 > 1" color="#eb6573" class="config-teste">
                    <span class="text-center">Informações básicas</span>
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2" :complete="e1 > 2" color="#eb6573">
                    <span class="text-center">Adicionar Músicas</span>
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3" :complete="e1 > 3" color="#eb6573">
                    <span class="text-center">Detalhes da Publicação</span>
                </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>

                <v-stepper-content step="1">
                    <v-container>
                        <v-row>
                            <v-col cols="12" lg="12">
                                <app-metadata-basic-info @increment="incrementStep" @album="album = $event" @albumDetails="albumDetails = $event" @albumCover="albumCover = $event"></app-metadata-basic-info>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-stepper-content>
                
                <v-stepper-content step="2">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <app-metadata-add-music @increment="incrementStep" :album="album" :albumDetails="albumDetails" @decrement="decrementStep" :albumCover="albumCover" @musics="musics = $event"></app-metadata-add-music>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <app-metadata-resume-step @decrement="decrementStep" :album="album" :albumDetails="albumDetails" :musics="musics" :albumCover="albumCover"></app-metadata-resume-step>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>

<script>
import MetadataBasicInfo from '../components/MetadataBasicInfo.vue'
import MetadataAddMusic from '../components/MetadataAddMusic.vue'
import MetadataResumeStep from '../components/MetadataResumeStep.vue'

export default {
    components: {
        "app-metadata-basic-info": MetadataBasicInfo,
        "app-metadata-add-music": MetadataAddMusic,
        "app-metadata-resume-step": MetadataResumeStep
    },
    data: () => ({
        e1: 1,
        album: {},
        musics: [],
        albumCover: '',
        albumDetails: {
            labelName: '',
            artistName: '',
            albumTypeName: '',
            mainGenreName: '',
            albumMembers: []
        }
    }),
    methods: {
        incrementStep() {
            this.e1++;
        },
        decrementStep() {
            this.e1--;
        }
    } 
}
</script>

<style lang="scss">
    #stepper {
        background: #ffffff00;
        box-shadow: none !important;
    }
    .bg-card-content {
        background-color: rgb(0 0 0 / 41%);
        padding: 2rem;
        margin-bottom: 30px;
        height: auto;
    }
</style>