var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-loading-full',{attrs:{"active":_vm.loading}}),_c('h3',{staticClass:"white--text mt-6"},[_vm._v("USUÁRIOS CADASTRADOS")]),_c('div',{staticClass:"mt-6 d-flex justify-end flex-wrap-reverse"},[_c('v-btn',{staticClass:"mr-4 clear-button button-clear-size",attrs:{"outlined":"","dark":"","color":"white","height":"40"},on:{"click":_vm.redirectUserRegisterPage}},[_c('span',[_vm._v("Cadastrar")])])],1),_c('v-card',{staticClass:"mt-4",attrs:{"dark":"","color":"#071c33"}},[_c('v-col',{staticClass:"mb-4",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar Usuário","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1 bg-table",attrs:{"loading":_vm.loading,"loading-text":"Carregando...","headers":_vm.headers,"items":_vm.data,"search":_vm.search,"item-key":"id_user"},scopedSlots:_vm._u([{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.no_first_name)+" "+_vm._s(item.no_last_name))]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(item.is_active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline")+" ")])]}},{key:"item.actions",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"plain":""},on:{"click":_vm.openUserSystemAccessModal}},on),[_c('v-icon',_vm._b({attrs:{"size":"16"}},'v-icon',attrs,false),[_vm._v("mdi-lock")])],1)]}}])},[_c('span',[_vm._v("Liberar Acesso")])])]},proxy:true},{key:"item.actions2",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":'/editar/user/' + item.id_user}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-eye")])],1)]}}])})],1),_c('app-notification',{attrs:{"active":_vm.notification.active,"type":_vm.notification.type,"message":_vm.notification.message},on:{"notificationStatus":_vm.changeNotificationStatus}}),_c('app-user-system-access',{attrs:{"open":_vm.userSystemAccess.open,"userId":_vm.userSystemAccess.userId,"unlock":_vm.userSystemAccess.unlock},on:{"changeDisplayOpen":_vm.changeDisplayOpen}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }