import Vue from 'vue'
import axios from 'axios'

const baseURLAuth = 'https://auth.v1.musicpost.com.br';
const baseURLDist = 'https://dist.v1.musicpost.com.br';
const baseURLLocation = 'https://location.v1.emotionstudios.com.br';
const baseURLReport = 'https://0t5z5sboha.execute-api.us-east-1.amazonaws.com/dev'


function createApiHeader(key, token) {
    const headers = {
        'access-control-allow-headers': '*',
        'access-control-allow-methods': '*',
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'x-api-key': key
    }
    if (token) {
        headers['x-access-token'] = token;
    }
    return headers
}

const headerAuth = createApiHeader('tKWlGesiUtaPrD21nEgJ4awrurz3l7yC2EZM9WLL', null);
const headerAuthtoken = createApiHeader('tKWlGesiUtaPrD21nEgJ4awrurz3l7yC2EZM9WLL', localStorage.access_token);
const headerDist = createApiHeader('mL55l14pPIkigZm90HXc3B94lMd4zeMYa0gRcVc0', localStorage.access_token);
const headerLocation = createApiHeader('lT5fqUwiIC3cjvDMKw1lc4cFvsCstI9D5S0sV4WL', localStorage.access_token);

const apiAuth = axios.create({
    baseURL: baseURLAuth,
    headers: headerAuth
});
const apiDist = axios.create({
    baseURL: baseURLDist,
    headers: headerDist
});
const apiAuthToken = axios.create({
    baseURL: baseURLAuth,
    headers: headerAuthtoken
});
const apiLocation= axios.create({
    baseURL: baseURLLocation,
    headers: headerLocation
});
const apiReport = axios.create({
    baseURL: baseURLReport,
});

Vue.use({
    install(Vue) {
        Vue.prototype.$httpAuth = apiAuth,
        Vue.prototype.$httpDist = apiDist,
        Vue.prototype.$httpAuthToken = apiAuthToken,
        Vue.prototype.$httpLocation = apiLocation,
        Vue.prototype.$httpReport = apiReport
    }
})