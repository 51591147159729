<template>
    <div class="header-profile">
        <div class="d-flex align-end justify-space-between">
            <!-- <v-avatar
                size="148"
                class="mr-8"
                color="#fff"
            >
                <img v-if="profilePicture" :src="profilePicture" alt="">
                <v-icon v-else size="120" color="#071d35">mdi-account</v-icon>
            </v-avatar> -->
            <div class="d-flex flex-column">
                <span class="type-profile">{{profileType}}</span>
                <span class="name-profile">{{profileName}}</span>
            </div>
            <div v-if="options" class="d-flex">
                <router-link :to="'/editar/artista/' + $route.params.id" class="text-decoration-none" v-if="profileType == 'Artista'">
                    <v-btn
                        class="mx-2"
                        text
                        icon
                        color="grey lighthen-1"
                    >
                        <v-icon size="36">
                            mdi-square-edit-outline
                        </v-icon>
                    </v-btn>
                </router-link>
                <app-delete-label :idLabel="idLabel" v-if="mainType == 'Client' && profileType == 'Selo'"></app-delete-label>
                <app-delete-artist :idArtist="idArtist" v-if="mainType != 'Artist' && profileType == 'Artista'"></app-delete-artist>
            </div>
        </div>
    </div>
</template>

<script>
import DeleteLabelVue from './DeleteLabel.vue';
import DeleteArtistVue from './DeleteArtist.vue';

export default {
    props: {
        profileName: {
            type: String,
            required: true,
            default: 'Nome'
        },
        profileType: {
            type: String,
            required: true,
            default: 'Tipo'
        },
        options: {
            type: Boolean,
            required: false,
            default: false
        },
        idLabel: {
            type: Number,
            required: false,
            default: 0
        },
        idArtist: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data: () => ({
        profilePicture: '',
        mainType: localStorage.tp_profile
    }),
    components: {
        'app-delete-label': DeleteLabelVue,
        'app-delete-artist': DeleteArtistVue
    }
}
</script>

<style>
    .header-profile {
        padding-bottom: 12px;
        border-bottom: 1px solid #fff;
    }

    .type-profile {
        text-transform: uppercase;
        font-size: 1.125rem;
        color: #fff;
        line-height: 1.33;
    }

    .name-profile {
        font-size: 2.875rem;
        font-weight: bold;
        line-height: 1.3;
        color: #eb6573;
        text-transform: uppercase;
    }
    
    .over-icon {
        color: white;
    }
    @media only screen and (max-width: 600px){
        .name-profile {
            font-size: 1.5rem;
        }
    }
</style>