<template>
    <div>
        <app-loading :active="loading"></app-loading>
        <v-form
            ref="form"
            v-model="hableSubmit"
        >
            <v-row>
                <v-col cols="12" :md="typeUser == 'Client'? 3 : 4">
                    <v-text-field
                        v-model="firstName"
                        :rules="[rules.required]"
                        label="Primeiro Nome"
                        required
                        dark
                        class="fluid"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" :md="typeUser == 'Client'? 3 : 4">
                    <v-text-field
                        v-model="secondName"
                        label="Segundo Nome"
                        dark
                        class="fluid"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" :md="typeUser == 'Client'? 3 : 4">
                    <v-text-field
                        v-model="email"
                        :rules="[rules.required, rules.email]"
                        label="Email"
                        required
                        dark
                        class="fluid"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        dark
                        v-model="type"
                        :rules="[rules.required]"
                        :items="types"
                        item-text="desc"
                        item-value="id"
                        label="Tipo"
                        @change="getInfos"
                        v-if="typeUser == 'Client'"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="type === 1">
                <v-col cols="12" v-if="typeUser == 'Client'" class="d-flex">
                    <v-checkbox
                        dark
                        v-model="hasLabel"
                        label="O artista pertence a algum Selo"
                    ></v-checkbox>
                    <v-autocomplete
                        v-if="hasLabel"
                        dark
                        class="ml-12"
                        v-model="labelSelected"
                        :items="labels"
                        item-text="no_label"
                        item-value="id_label"
                        label="Selecione o selo que este artista pertence"
                        :rules="[rules.required]"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                    <h3 class="white--text">INFORMAÇÕES BÁSICAS</h3>
                </v-col>
                <v-col cols="6" md="3">
                    <v-text-field
                        v-model="basicInformation.interpreter"
                        label="Intérprete"
                        required
                        dark
                        class="fluid"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                    <v-select
                        dark
                        v-model="basicInformation.mainGenre"
                        :items="primaryGenres"
                        item-text="no_genre"
                        item-value="id_genre"
                        label="Gênero Principal"
                    ></v-select>
                </v-col>
                <v-col cols="6" md="3">
                    <v-select
                        dark
                        v-model="basicInformation.secondaryGenre"
                        :items="secondaryGenres"
                        item-text="no_genre"
                        item-value="id_genre"
                        label="Gênero Secundário"
                    ></v-select>
                </v-col>
                <v-col cols="6" md="3">
                    <v-select
                        dark
                        v-model="basicInformation.typeOfPoetryOnYoutube"
                        :items="typesOfPoetry"
                        label="Tipo da Política no YouTube"
                    ></v-select>
                </v-col>
                <v-col cols="6" md="4">
                    <v-text-field
                        v-model="basicInformation.idAppleMusic"
                        label="Apple Music ID"
                        prepend-inner-icon="mdi-apple"
                        required
                        dark
                        class="fluid"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                    <v-text-field
                        v-model="basicInformation.idSpotify"
                        label="Spotify ID"
                        prepend-inner-icon="mdi-spotify"
                        required
                        dark
                        class="fluid"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                    <v-select
                        dark
                        v-model="basicInformation.language"
                        :items="languages"
                        item-text="no_language"
                        item-value="id_language"
                        label="Idioma Principal"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <v-textarea
                        v-model="basicInformation.biography"
                        label="Biografia"
                        auto-grow
                        dark
                    ></v-textarea>
                </v-col>
            </v-row>
            <div class="mt-12 d-flex justify-end">
                <v-btn
                    class="mr-4 clear-button button-clear-size"
                    outlined
                    dark
                    color="white"
                    height="40"
                    @click="$router.go(-1)"
                >
                    <span>Voltar</span>
                </v-btn>
                <v-btn
                    class="mr-4 clear-button"
                    outlined
                    dark
                    color="white"
                    height="40"
                    @click="reset"
                >
                    Limpar
                </v-btn>
                <v-btn
                    :disabled="!hableSubmit"
                    class="submit-button"
                    height="40"
                    @click="submit"
                >
                    <span v-if="loading">Salvando</span>
                    <span v-else>Salvar</span>
                </v-btn>
            </div>
        </v-form>
        <app-notification 
            @notificationStatus="changeNotificationStatus" 
            :active="notification.active"
            :type="notification.type"
            :message="notification.message"    
        >
        </app-notification>
    </div>
</template>

<script>
import { postUser, errorMessage } from '../services/User';
import { getGenre } from '../services/Genre';
import { getLanguage } from '../services/Location';
import { getLabels, postLabel } from '../services/Label';
import { postArtist } from '../services/Artist';
import PageLoading from './PageLoading.vue';
import Notification from './Notification.vue';

export default {
    components: { 
        'app-loading': PageLoading,
        'app-notification': Notification
    },
    created() {
        if (this.typeUser == 'Client') {
            this.types = [{id: 6, desc: 'Master'}, { id: 2, desc: 'Selo' }, { id: 1, desc: 'Artista' }]
        } else {
            this.types = [{ id: 1, desc: 'Artista' }];
            this.type = 1;
            this.labelSelected = parseInt(localStorage.id_profile_entity);
            this.getInfos();
        }    
    },
    data: () => ({
        loading: false,
        hableSubmit: true,
        firstName: '',
        secondName: '',
        email: '',
        type: '',
        typeUser: localStorage.tp_profile,
        types: [],
        basicInformation: {
            interpreter: '',
            mainGenre: '',
            secondaryGenre: '',
            typeOfPoetryOnYoutube: '',
            biography: '',
            idSpotify: '',
            idAppleMusic: '',
            language: ''
        },
        primaryGenres: [],
        secondaryGenres: [],
        languages: [],
        labels: [],
        labelSelected: null,
        hasLabel: false,
        typesOfPoetry: ['Monetização', 'Bloqueio', 'Rastreamento'],
        rules: {
            required: value => !!value || 'Este campo é obrigatório.',
            email: v => /.+@.+\..+/.test(v) || 'E-mail inválido'
        },
        notification: {
            active: false,
            message: '',
            type: ''
        }
    }),
    methods: {
        reset () {
            this.$refs.form.reset();
            if (this.typeUser != 'Client') {
                this.type = 1;
            }   
        },

        createMasterUser(user) {
            user.user_profiles = [
                {
                    "profile": { "id_profile": 6 }
                }
            ]
            postUser(user)
            .then(() => {
                this.notification = {
                    active: true,
                    message: "Usuário cadastrado com sucesso!",
                    type: 'success'
                };
                this.reset();
            })
            .catch(err => {
                this.notification = {
                    active: true,
                    message: errorMessage(err.response.status),
                    type: 'error'
                }
            })
            .finally(() =>{
                this.loading = false;
                this.hableSubmit = true;
            })
        },

        createLabelUser(user) {
            postLabel(user)
            .then(() => {
                this.notification = {
                    active: true,
                    message: "Usuário cadastrado com sucesso!",
                    type: 'success'
                };
                this.reset();
            })
            .catch(err => {
                this.notification = {
                    active: true,
                    message: errorMessage(err.response.status),
                    type: 'error'
                }
            })
            .finally(() =>{
                this.loading = false;
                this.hableSubmit = true;
            })
        },

        createArtistUser(user) {

            if (!this.labelSelected) {

                let userLabelArtist = user;

                userLabelArtist.user_profiles = [
                    {
                        "profile": { "id_profile": 2 }
                    }
                ];

                postUser(userLabelArtist)
                .then(res => {
                    const { data } = res.data;
                    const userIdLabelArtist = { "id_user": data.id_user };

                    postArtist(user, this.basicInformation, null, userIdLabelArtist)
                    .then(() => {
                        this.notification = {
                            active: true,
                            message: "Usuário cadastrado com sucesso!",
                            type: 'success'
                        };
                        this.reset();
                    })
                    .catch(err => {
                        this.notification = {
                            active: true,
                            message: errorMessage(err.response.status),
                            type: 'error'
                        }
                    })
                    .finally(() =>{
                        this.loading = false;
                        this.hableSubmit = true;
                    })
                })
                .catch(err => {
                    this.notification = {
                        active: true,
                        message: errorMessage(err.response.status),
                        type: 'error'
                    };
                    this.loading = false;
                    this.hableSubmit = true;
                })
            } else {
                postArtist(user, this.basicInformation, this.labelSelected, null)
                .then(() => {
                    this.notification = {
                        active: true,
                        message: "Usuário cadastrado com sucesso!",
                        type: 'success'
                    };
                    this.reset();
                })
                .catch(err => {
                    this.notification = {
                        active: true,
                        message: errorMessage(err.response.status),
                        type: 'error'
                    }
                })
                .finally(() =>{
                    this.loading = false;
                    this.hableSubmit = true;
                })
            }
        },

        submit() {
            this.loading = true;
            this.hableSubmit = false;

            if(this.$refs.form.validate()) {

                const user = {
                    "no_first_name": this.firstName,
                    "no_last_name": this.secondName,
                    "ds_email": this.email
                };

                switch (this.type) {
                    //artist
                    case 1:
                        this.createArtistUser(user);
                        break;
                    //label
                    case 2:
                        this.createLabelUser(user);
                        break;
                    //master
                    case 6: 
                        this.createMasterUser(user);
                        break;
                }

                window.scrollTo(0, 0);
            }
        },

        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        },

        getLabels() {
            const labelResponse = getLabels();
            labelResponse
            .then(res => {
                const { data } = res.data;
                this.labels = data.map( label => ({
                    "id_label": label.id_label,
                    "no_label": label.no_label
                }))
            })
            .catch(err => {
                console.log(err);
            })
        },

        getGenreList() {
            const genreResponse = getGenre();
            genreResponse
            .then(res => {
                const { data } = res.data;
                this.primaryGenres = data.filter( genre => genre.is_subgenre == false);
                this.secondaryGenres = data.filter( genre => genre.is_subgenre == true);
            })
            .catch(err => {
                console.log(err);
            })
        },

        getLanguageList() {
            const languageResponse = getLanguage();
            languageResponse
            .then(res => {
                const { data } = res.data;
                this.languages = data.map( language => ({
                    "id_language": language.id_language,
                    "no_language": language.no_language
                }))
            })
            .catch(err => {
                console.log(err);
            })
        },

        getInfos() {
            if (this.type !== 1) return;
            if (this.primaryGenres.length > 0 &&
                this.secondaryGenres.length > 0 &&
                this.languages.length > 0) 
            return;

            this.getGenreList();
            this.getLanguageList();
            this.getLabels();
        }
    }
}
</script>

<style scoped lang="scss">
    .clear-button {
        width: 100%;
        max-width: 156px !important;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    .submit-button {
        max-width: 156px !important;
    }
</style>