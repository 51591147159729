<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-card dark color="#071c33">
      <v-card-title>
        <span class="text-h5">LIBERAR ACESSO AO SISTEMA</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field label="Senha" type="password" required></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Confirmar Senha" type="password" required></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeModal">Cancelar</v-btn>
        <v-btn color="blue darken-1" text @click="getArtistData">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getArtistById } from "../services/Artist"
export default {
  props: {
    open: {
      required: true,
      type: Boolean,
    },
    userId: {
      required: true,
      type: Number,
    },
    unlock: {
      required: true,
      type: Boolean,
    },

  },
  data: () => ({
    params: '',
    id_artist: '',
    no_artistic_name: '',
  }),
  methods: {
    closeModal() {
      this.$emit("changeDisplayOpen", false);
    },
    getArtistData() {
      const artistResponse = getArtistById(this.$route.params.id);
      artistResponse
        .then(res => {
          const { data } = res.data;
          this.id_artist = data.id_artist,
            this.no_artistic_name = data.no_artistic_name,
            this.$router.push({ path: `/data/${this.no_artistic_name}/${this.id_artist}` });
        }).catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        })
    },
  },
};
</script>
