<template>
    <div v-if="active">
        <v-overlay z-index="998" :opacity="opacity" color="#071d35">
            <div class="d-flex flex-column align-center">
                <img src="https://uploads-ssl.webflow.com/60b08e74da62702fe0f67593/60b08e74da62708878f67671_preloader-hitbel.svg" width="130px" alt="Loading" class="logo-animation"/>
                <h1 class="white--text">{{ text }}</h1>
            </div>
        </v-overlay>
    </div>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false,
            required: false
        },
        opacity: {
            type: Number,
            default: 1,
            required: false
        },
        text: {
            type: String,
            default: 'carregando...',
            required: false
        }
    }
}
</script>

<style scoped lang="scss">
    .logo-animation {
        animation: logo-animation infinite 1s alternate linear; 
    }
    
    @keyframes logo-animation {
        from {
            transform: translateY(-20px)
        }
        to {
            transform: translateY(0px)
        }
    }
</style>