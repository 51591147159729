<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
    >
        <template v-slot:activator="{ on, attrs }">
            <v-icon size="24" :v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
        </template>
        <v-card tile dark>
            <v-toolbar flat dark :max-height="80" color="#512f87" class="d-flex align-center">
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>AJUDA PARA O PREENCHIMENTO DOS METADADOS</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="mt-6">
                <h3 class="white--text">Primeiro, vamos te ajudar a adicionar as informações básicas do seu produto. Vamos lá?</h3>
                <h3 class="title mt-4">Capa (*Obrigatório)</h3>
                <p class="mt-4 body-1">
                    O primeiro passo é inserir a capa do produto. Na própria página, 
                    você consegue visualizar as orientações que precisa para fazer o seu upload 
                    sem erro!

                    <ul class="ml-6 mt-2">
                        <li>Formato do arquivo: JPEG</li>
                        <li>Tamanho: pelo menos 1400 x 1400 pixels</li>
                        <li>Resolução: 72dpi</li>
                        <li>Sua arte não pode conter logos</li>
                        <li>Sua arte não pode conter endereço de sites</li>
                        <li>Sua arte não pode conter datas ou anúncios</li>
                    </ul>
                </p>
                <p class="mt-4 body-1">
                    Lembrando que esse item é obrigatório, pois sem a capa é impossível distribuir o seu produto.
                </p>
                <h3 class="title mt-4">Tipo (*Obrigatório)</h3>
                <p class="body-1 mt-4">
                    Neste campo você deve especificar se seu produto é um:

                    <ul class="ml-6 mt-2">
                        <li>
                            <span class="white--text font-weight-bold">Single: </span>
                            1 ou 2 músicas com duração total de até 10 minutos
                        </li>
                        <li>
                            <span class="white--text font-weight-bold">EP: </span>
                            Contém até 6 músicas, com a duração total de até 30 minutos
                        </li>
                        <li>
                            <span class="white--text font-weight-bold">Álbum: </span>
                            Possui mais de 6 músicas ou mais de 30 minutos
                        </li>
                    </ul>
                </p>

                <h3 class="title mt-4">Participação (*Obrigatório)</h3>
                <p class="body-1 mt-4">
                    Neste campo é muito importante você sinalizar se o seu projeto conta com a 
                    participação de outros artistas, especificando o tipo da parceria:
                    <ul class="ml-6 mt-2">
                        <li>
                            <span class="white--text font-weight-bold">Feat: </span>
                            é atribuído a você o título de artista principal e o seu convidado é 
                            identificado como participação especial. 
                        </li>
                        <li>
                            <span class="white--text font-weight-bold">Collab: </span>
                            mais de um artista principal ou banda. Nesse caso, 
                            a música é distribuída nas páginas de todos os artistas principais, 
                            mas a receita é direcionada àquele que distribui o produto.
                        </li>
                    </ul>
                </p>
                <p class="mt-4 body-1">* Caso seja necessário, adicione mais participações clicando no “+”.</p>

                <h3 class="title mt-4">Gênero e idiomas (*Obrigatório)</h3>
                <p class="body-1 mt-4">
                    Preencha a categoria do seu projeto:
                    <ul class="ml-6 mt-2">
                        <li>
                            <span class="white--text font-weight-bold">Gênero principal: </span>
                            insira o gênero predominante do produto
                        </li>
                        <li>
                            <span class="white--text font-weight-bold">Gênero secundário: </span>
                            insira o gênero secundário do produto
                        </li>
                        <li>
                            <span class="white--text font-weight-bold">Idioma do Álbum/Músicas: </span>
                            preencha o idioma do produto
                        </li>
                    </ul>
                </p>

                <h3 class="title mt-4">Lançamentos e Datas (*Obrigatório)</h3>
                <p class="body-1 mt-4">
                    Sinalize se esse produto é uma migração, ou seja, se você está transferindo o 
                    produto que já foi entregue às plataformas em algum momento.
                    <ul class="ml-6 mt-2">
                        <li>
                            <span class="white--text font-weight-bold">Data de lançamento original: </span>
                            Data oficial do lançamento. No caso de uma migração, a data da primeira distribuição
                        </li>
                        <li>
                            <span class="white--text font-weight-bold">Data de migração: </span>
                            Data em que o produto estará disponível nas plataformas após sua transferência
                        </li>
                        <li>
                            <span class="white--text font-weight-bold">Copyright e data de produção: </span>
                            Insira o(a) proprietário(a) da produção visual (capa)
                        </li>
                        <li>
                            <span class="white--text font-weight-bold">Copyright e data de gravação: </span>
                            Insira o(a) proprietário(a) e o ano de produção de gravação da(s) música(s)
                        </li>
                    </ul>
                </p>

                <h3 class="title mt-4">UPC (Opcional)</h3>
                <p class="body-1 mt-4">
                    Sinalize se esse produto é uma migração, ou seja, se você está transferindo o 
                    produto que já foi entregue às plataformas em algum momento.
                </p>

                <hr class="mt-4 mb-4 gray" />
                
                <h3 class="white--text">Após completar as informações indicadas acima, você deve  preencher os dados referentes à(s) faixa(s).</h3>
                
                <h3 class="mt-4">INFORMAÇÕES BÁSICAS</h3>

                <h3 class="title mt-4">Título (*Obrigatório)</h3>
                <p class="body-1 mt-4">
                    Aqui você insere o nome da sua música. Evite utilizar hashtags para que as 
                    plataformas aceitem suas músicas, sem possibilidade de recusa ao seu conteúdo.
                </p>

                <h3 class="title mt-4">Participação (Opcional)</h3>
                <p class="body-1 mt-4">
                    Neste campo é muito importante você sinalizar se o seu projeto conta com a 
                    participação de outros artistas, especificando o tipo da parceria:
                    <ul class="ml-6 mt-2">
                        <li>
                            <span class="white--text font-weight-bold">Feat: </span>
                            é atribuído a você o título de artista principal e o seu convidado é identificado como participação especial. 
                        </li>
                        <li>
                            <span class="white--text font-weight-bold">Collab: </span>
                            mais de um artista principal ou banda. Nesse caso, a música é 
                            distribuída nas páginas de todos os artistas principais, mas a receita é direcionada àquele que distribui o produto.
                        </li>
                    </ul>
                </p>
                <p class="mt-4 body-1">* Caso seja necessário, adicione mais participações clicando no “+”.</p>

                <h3 class="title mt-4">Versão (Opcional)</h3>
                <p class="body-1 mt-4">
                    Especifique se a música se trata de uma versão acústica, remix, playback, ao vivo, etc.
                </p>
                <h3 class="title mt-4">Gênero e idiomas (*Obrigatório)</h3>
                <p class="body-1 mt-4">
                    Preencha a categoria do seu projeto:
                    <ul class="ml-6 mt-2">
                        <li>
                            <span class="white--text font-weight-bold">Gênero principal: </span>
                            insira o gênero predominante do produto
                        </li>
                        <li>
                            <span class="white--text font-weight-bold">Gênero secundário: </span>
                            insira o gênero secundário do produto
                        </li>
                        <li>
                            <span class="white--text font-weight-bold">Idioma do Álbum/Músicas: </span>
                            preencha o idioma do produto
                        </li>
                    </ul>
                </p>

                <h3 class="mt-4">INFORMAÇÕES AVANÇADAS</h3>

                <h3 class="title mt-4">Idioma da música (*Obrigatório)</h3>
                <p class="body-1 mt-4">
                   Sinalize se é um conteúdo nacional ou estrangeiro
                </p>

                <h3 class="title mt-4">ISRC: (*Obrigatório) </h3>
                <p class="body-1 mt-4">
                   Insira aqui o código exclusivo atribuído à sua gravação
                </p>

                <h3 class="title mt-4">Letra da música (Opcional):</h3>
                <p class="body-1 mt-4">
                    Transcreva sua música completa, incluindo as partes repetidas<br>
                    <span class="text--white">* Especifique se for o caso de uma versão instrumental ou se possui conteúdo explícito</span>
                </p>
                
                <h3 class="title mt-4">Créditos da música (*Obrigatório):</h3>
                <p class="body-1 mt-4">
                   Preencha o nome dos profissionais que participaram do seu projeto
                </p>
                <ul class="ml-6 mt-2">
                    <li>
                        <span class="white--text font-weight-bold">Produtores: </span>
                        Responsáveis por toda a produção
                    </li>
                    <li>
                        <span class="white--text font-weight-bold">Compositores: </span>
                        Pessoas que detém os direitos de composição
                    </li>
                    <li>
                        <span class="white--text font-weight-bold">Editoras: </span>
                        Identifique a editora caso os compositores sejam vinculados.
                    </li>
                </ul>
                <p class="mt-4">* Se não, preencha o campo com “Direitos Reservados”</p>

                <hr class="mt-4 mb-4 gray" />

                <h3 class="title mt-4">Remoção de dados permanente</h3>
                <p class="body-1 mt-4">
                   Para solicitar uma exclusão de seus dados permanentemente 
                   <a 
                    target="_blank"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdW3eEIn0lTyMFz9c6fDpiCRQZ59y2APMlb_Pka0RQjDIGAfw/viewform"
                    >
                        clique aqui
                    </a>
                </p>
                <p>
                    
                </p>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
};
</script>
<style scoped> 
    .title {
        color: #eb6573;
        text-transform: uppercase;
    }
</style>
