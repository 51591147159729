import Vue from 'vue'

export async function postUserCredentials(login, password, profile, token) {
    let params = {};
    if (profile && token) {
        params = {
            token: token,
            "grant_type": "client_credentials",
            "scope": {
                "client": "796A9EA75AE1938A94CD75C5279A4",
                "profile": profile
            }
        }
    } else {
        params = { 
            "user": login,
            "pass": password,
            "grant_type": "client_credentials",
            "scope": {
                "client": "796A9EA75AE1938A94CD75C5279A4"
            }
        }
    }

    const response = await Vue.prototype.$httpAuth
    .post('/credentials', params);
    return response
}

export async function logout() {
    const response = await Vue.prototype.$httpAuthToken.get('/logout');
    return response
}

export function errorMessage(status) {
    let message = "";
    switch (status) {
        case 401:
            message = 'Usuário e/ou senha inválidos.'
            break;
        
        case 500:
            message = 'Algo deu errado. Já estamos resolvendo.'
            break;
    
        default:
            message = 'Aconteceu um probleminha, não se preocupe. Já estamos resolvendo.'
            break;
    }
    return message
}

export async function recoverPassword(email){
    const response = await Vue.prototype.$httpAuth.put('/user/password/'+ btoa(email), {
        "ds_key": "796A9EA75AE1938A94CD75C5279A4"
    });
    return response
}

