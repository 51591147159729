<template>
    <div>
        <app-profile-header :profileName="profileName" :profileType="profileType"></app-profile-header>
        <app-list-labels v-on:labelSelected="labelSelected = $event"></app-list-labels>
    </div>
</template>

<script>
import ListLabels from '../components/ListLabels.vue'
import ProfileHeader from "../components/ProfileHeader"

export default {
    components: {
        'app-profile-header': ProfileHeader,
        'app-list-labels': ListLabels
    },
    data: () => ({
        profileType: 'Admin',
        profileName: localStorage.no_profile
    })
}
</script>

<style>

</style>