import Vue from 'vue'

export async function getUser(id) {
    const response = await Vue.prototype.$httpAuthToken
        .get('/user/' + id);
    return response
}

export async function getUsers() {
    const response = await Vue.prototype.$httpAuthToken
        .get('/user');

    return response;
}

export async function postUser(user) {
    if (user.no_last_name === null) {
        user.no_last_name = '';
    }
    const response = await Vue.prototype.$httpAuthToken
        .post('/user', user);
    return response
}

export async function putPassword(params, token, id) {
    const response = await Vue.prototype.$httpAuth
        .put('/user/' + id, params, {
            headers: {
                'x-Access-Token': token
            }
        });
    return response
}



export async function putUser(user) {
    const response = await Vue.prototype.$httpAuthToken
        .put('/user/' + user.id_user,{
            id_user: user.id_user,
            no_first_name: user.no_first_name,
            no_last_name: user.no_last_name,
            ds_email: user.ds_email,
            ds_address: user.ds_address,
            nu_phone: user.nu_phone,
            no_profile: user.no_profile,
            is_active: user.is_active,
          });
    return response
}


export function errorMessage(status) {
    let message = "";
    switch (status) {
        case 409:
            message = 'Este email já está sendo utilizado! Tente novamente com um email diferente.'
            break;

        case 500:
            message = 'Algo deu errado. Já estamos resolvendo.'
            break;

        default:
            message = 'Aconteceu um probleminha, não se preocupe. Já estamos resolvendo.'
            break;
    }
    return message
}
