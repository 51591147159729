<template>
    <div>
        <v-app-bar
            color="#ffffff00"
            dark
            flat
            elevation="0"
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <img src="../assets/logo-hitbel.svg" alt="" height="30px" class="ml-8">
            <div  class="user-circle-icon">
                <router-link :to="'/pegar/user/150'">
                    <v-btn
                        class="mx-2"
                        text
                        icon
                        color="grey lighthen-1"
                    >
                        <v-icon size="36">
                            mdi-account-circle
                        </v-icon>
                    </v-btn>
                </router-link>
            </div>

            <!-- <div class="user-circle-icon">
                <button type="button">
                    <v-icon size="30">mdi-account-circle</v-icon>
                </button>
            </div> -->
            <v-spacer></v-spacer>

            <!-- <v-btn icon>
                <v-icon size="24" v-show="false">mdi-bell</v-icon>
            </v-btn> -->

            <!-- <v-btn icon>
                <v-icon size="24">mdi-help-circle-outline</v-icon>
            </v-btn> -->
            <app-metadata-helper></app-metadata-helper>

            <!-- <v-btn icon>
                <v-avatar>
                    <v-icon dark size="36" v-show="false">
                        mdi-account-circle
                    </v-icon>
                </v-avatar>
            </v-btn> -->
        </v-app-bar>

        <v-navigation-drawer
            v-model="drawer"
            fixed
            :temporary="false"
            disable-resize-watcher
            :mini-variant="!menuCompact.hidden"
            mini-variant-width="100"
            app
            dark
            overlay-color="#ffffff00"
            class="nav-drawer"
            width="350"

        >
           <div class="nav-drawer-top">
                <div class="d-flex justify-center align-center my-3">
                    <img src="../assets/logo-hitbel.svg" alt="" height="30px" class="ma-12">
                </div>
                <app-navigation-item navigateItem="Home" icon="mdi-home" item="Home"></app-navigation-item>
                <app-navigation-item navigateItem="Users" icon="mdi-account" item="Usuários" v-if="typeUser !== 'Artist'"></app-navigation-item>
                <app-navigation-item navigateItem="List Metadados" icon="mdi-music" item="Metadados"></app-navigation-item>
                <app-navigation-item navigateItem="Financial Report" icon="mdi-finance" item="Financeiro" v-if="typeUser !== 'Artist'"></app-navigation-item>
            </div>
            <div class="nav-drawer-bottom">
                <div class="d-flex justify-center my-3">
                    <app-logout icon="mdi-logout" text="Sair"></app-logout>
                </div>
            </div>
        </v-navigation-drawer>
    </div>
</template>

<script>
import NavigationItem from '../components/NavigationItem.vue'
import Logout from '../components/Logout.vue'
import MetadataHelper from './MetadataHelper.vue'

export default {
    components: { 
        'app-navigation-item': NavigationItem,
        'app-logout': Logout,
        'app-metadata-helper': MetadataHelper
    },
    data: () => ({
        drawer: false,
        group: null,
        typeUser: localStorage.tp_profile,
        menuCompact: {
        hidden: true,
      },
    }),
     methods: {
        getUser() {
            this.idUser = localStorage.tp_profile
            console.log(this.idUser)
        }

     },

    watch: {
        group () {
            this.drawer = false
        },
    }
}
</script>

<style lang="scss">
    .nav-drawer {
        background-image: linear-gradient(to bottom, #0f1f3f, #09152c);
        // max-height: 100vh;
        // position: fixed;
    }
    // .v-navigation-drawer__content {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: space-between;
    //     max-height: 100vh;
    // }
    .user-circle-icon{
        display: flex;
        justify-content: space-between;
        position: absolute;
        right: 80px;
        cursor: pointer;
    }
    @media (max-width: 400px){
        .user-circle-icon{
            position: relative;
            left: 0px;
            padding-left: 25px;
            padding-right: 25px;
        }
    }
</style>