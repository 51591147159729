<template>
    <div class="bg-card-content">
        <app-loading :active="loading"></app-loading>
        <app-loading-full :active="loading"></app-loading-full>
        <v-form
            ref="form"
            v-model="hableSubmit"
        >
            <v-row>
                <v-col cols="12" sm="6" md="5" lg="4" xl="3">
                    <v-avatar
                        tile
                        color="#f1f1f1"
                        size="90%"
                    >
                        <v-icon size="200" color="black">
                            mdi-account
                        </v-icon>
                    </v-avatar>
                </v-col>
                <v-col cols="12" sm="6" md="7" lg="8" xl="9">
                    <div class="d-flex justify-space-between border-bottom">
                        <span class="name-profile-edit">{{no_artistic_name}}</span>
                    </div>
                    <div class="my-8">
                        <div class="desc-profile-edit">Selo: 
                            <span>{{no_label}}</span>
                        </div>
                    </div>
                    <v-textarea
                        class="mb-4"
                        v-model="params.biography"
                        label="Biografia"
                        auto-grow
                        dark
                    ></v-textarea>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="12">
                    <h3 class="white--text">DADOS DO ARTISTA</h3>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field
                        v-model="no_artistic_name"
                        label="Nome"
                        :rules="[rules.required]"
                        required
                        dark
                        class="fluid"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field
                        v-model="params.interpreter"
                        label="Intérprete"
                        :rules="[rules.required]"
                        required
                        dark
                        class="fluid"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-select
                        dark
                        v-model="params.mainGenre"
                        :items="primaryGenres"
                        item-text="no_genre"
                        item-value="id_genre"
                        label="Gênero Principal"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-select
                        dark
                        v-model="params.secondaryGenre"
                        :items="secondaryGenres"
                        item-text="no_genre"
                        item-value="id_genre"
                        label="Gênero Secundário"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-select
                        dark
                        v-model="params.typeOfPoetryOnYoutube"
                        :items="typesOfPoetry"
                        label="Tipo da Política no YouTube"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-select
                        dark
                        v-model="params.language"
                        :items="languages"
                        item-text="no_language"
                        item-value="id_language"
                        label="Idioma Principal"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field
                        v-model="params.idAppleMusic"
                        label="Apple Music ID"
                        prepend-inner-icon="mdi-apple"
                        required
                        dark
                        class="fluid"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field
                        v-model="params.idSpotify"
                        label="Spotify ID"
                        prepend-inner-icon="mdi-spotify"
                        required
                        dark
                        class="fluid"
                    ></v-text-field>
                </v-col>
            </v-row>
            <div class="mt-6 d-flex justify-end flex-wrap-reverse">
                <v-btn
                    class="mr-4 clear-button button-clear-size"
                    outlined
                    dark
                    color="white"
                    height="40"
                    @click="$router.go(-1)"
                >
                    <span>Voltar</span>
                </v-btn>
                <v-btn
                    :disabled="!hableSubmit"
                    class="submit-button"
                    height="40"
                    @click="putArtist()"
                >
                    <span v-if="loading">SALVANDO</span>
                    <span v-else>SALVAR MUDANÇAS</span>
                </v-btn>
            </div>
        </v-form>
         <app-notification 
            @notificationStatus="changeNotificationStatus" 
            :active="notification.active"
            :type="notification.type"
            :message="notification.message"    
        >
        </app-notification>
    </div>
</template>

<script>
import { getArtistById, putArtist } from "../services/Artist"
import { getGenre } from '../services/Genre';
import { getLanguage } from '../services/Location';
import PageLoading from '../components/PageLoading.vue';
import LoadingFull from '../components/LoadingFull.vue';
import Notification from '../components/Notification.vue';

export default {
    components: { 
        'app-loading': PageLoading,
        'app-loading-full': LoadingFull,
        'app-notification': Notification
    },
    data: () => ({
        loading: false,
        params: '',
        avatar_url: '',
        id_artist: '',
        no_label: '',
        id_label: '',
        no_artistic_name: '',
        primaryGenres: [],
        secondaryGenres: [],
        languages: [],
        typesOfPoetry: ['Monetização', 'Bloqueio', 'Rastreamento'],
        hableSubmit: false,
        rules: {
            required: value => !!value || 'Este campo é obrigatório.'
        },
         notification: {
            active: false,
            message: '',
            type: ''
        }
    }),
    created() {
        this.getArtistData();
        this.getGenreList();
        this.getLanguageList();
    },
    methods: {
        getArtistData() {
            this.loading = true;
            const artistResponse = getArtistById(this.$route.params.id);
            artistResponse
            .then(res => {
                const { data } = res.data;
                this.avatar_url = data.avatar_url,
                this.id_artist = data.id_artist,
                this.no_label = data.label != null ? data.label.no_label : 'Artista Independente',
                this.id_label = data.id_label,
                this.no_artistic_name = data.no_artistic_name,
                this.params = JSON.parse(data.params);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            })
        },
        getGenreList() {
            const genreResponse = getGenre();
            genreResponse
            .then(res => {
                const { data } = res.data;
                this.primaryGenres = data.filter( genre => genre.is_subgenre == false);
                this.secondaryGenres = data.filter( genre => genre.is_subgenre == true);                
            })
            .catch(err => {
                console.log(err);
            })
        },
        getLanguageList() {
            const languageResponse = getLanguage();
            languageResponse
            .then(res => {
                const { data } = res.data;
                this.languages = data.map( language => ({
                    "id_language": language.id_language,
                    "no_language": language.no_language
                }))
            })
            .catch(err => {
                console.log(err);
            })
        },
        putArtist() {
            const artist = {
                id_artist: this.id_artist,
                no_artistic_name: this.no_artistic_name
            }
            putArtist(artist, this.params, this.id_label)
            .then(() => {
                this.notification = {
                    active: true,
                    message: "Informações atualizadas com sucesso!",
                    type: 'success'
                };
            })
            .catch(() => {
                this.notification = {
                    active: true,
                    message: "Ops! Ocorreu um erro. Tente novamente.",
                    type: 'error'
                }
            })
            .finally(() =>{
                this.loading = false;
                this.hableSubmit = true;
            })
        },
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        },
    }
}
</script>

<style scoped>
    .bg-card-content {
        background-color: rgb(0 0 0 / 41%);
        padding: 2rem;
        margin-bottom: 30px;
        height: auto;
        width: 100%
    }
    .border-bottom {
        padding-bottom: 6px;
        border-bottom: 1px solid #fff;
    }
    .name-profile-edit {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.3;
        color: #eb6573;
    }
    .desc-profile-edit {
        font-size: 1.25rem;
        text-align: left;
        color: #ffffff;
    }
     .clear-button {
        width: 156px;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    .submit-button {
        max-width: 250px !important;
    }

    .button-clear-size {
        width: 150px;
        border-radius: 12px;
    }
    @media only screen and (max-width: 600px){
        .submit-button {
            max-width: 100% !important;
        }

        .button-clear-size {
            width: 100% !important;
            border-radius: 12px;
            margin-right: 0px !important;
            margin-top: 24px;
        }
    }
</style>