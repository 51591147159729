<template>
    <div>
        <app-loading-full :active="loading" :text="desc"></app-loading-full>
        <v-row>
            <v-col cols="12" lg="8">
                <div class="bg-card-content">
                <h3 class="white--text">DETALHES DA PUBLICAÇÃO</h3>
                <v-row class="mt-6">
                    <v-col cols="12" sm="4" lg="5" xl="4">
                        <img :src="albumCover" width="100%" height="100%">
                    </v-col>
                    <v-col cols="12" sm="8" lg="7" xl="8">
                        <div class="border-bottom">
                            <span class="album-title">{{albumDetails.nameAlbum}}</span>
                        </div>
                        <div class="d-flex flex-column desc-album mt-4">
                            <span>Artista: {{albumDetails.artistName}}</span>
                            <span>Selo: {{albumDetails.labelName}}</span>
                            <span>Tipo: {{albumDetails.albumTypeName}}</span>
                        </div>
                        <div class="desc-album-opc d-flex flex-column mt-12">
                            <span>Gênero Principal:  {{albumDetails.mainGenreName}}</span>
                            <span>Data de lançamento:  {{moment(album.dt_release ? album.dt_release : album.dt_original_release).utc().format('DD/MM/YYYY')}}</span>
                            <span>UPC: {{albumDetails.upcAlbum}}</span>
                        </div>
                    </v-col>
                </v-row>
                <div class="border-bottom mt-6">
                    <h3 class="white--text">FAIXAS DO ÁLBUM</h3>
                </div>
                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-data-table
                            class="bg-table"
                            :headers="headers"
                            :items="albums"
                            :items-per-page="25"
                            hide-default-footer
                            dark
                            dense
                        >
                            <template v-slot:item.order_track="{ index }">
                                <span>{{index + 1}}</span>
                            </template>
                            <template v-slot:item.audio="{ item, index }">
                                <div>
                                    <audio :id="'play-' + index" :src="item.audio" type="audio/wav"></audio>
                                    <v-icon size="24" @click="playMusic(index)" color="#994995">mdi-play-circle</v-icon>
                                </div>
                            </template>
                            <!-- <template v-slot:item.participations="{ item }">
                                <span v-for="(participation, index) in item.participations" :key="index">
                                    <span v-if="participation.type">
                                        {{ participation.type == 5 ? 'Collab.' : 'Feat.'}} {{participation.artist}} {{ (index + 1) == item.participations.length  ? '' : ' / '}}
                                    </span>
                                    <span v-else>
                                        --
                                    </span>
                                </span>
                            </template> -->
                            <!-- <template v-slot:item.isrc="{ item }">
                                <span>{{item.recording.co_isrc}}</span>
                            </template> -->
                            <!-- <template v-slot:item.actions="{ item, index }">
                                <app-delete-music :nameMusic="item.no_title" :index="index" @removeMusic="removeMusic($event)"></app-delete-music>
                            </template> -->
                        </v-data-table>
                    </v-col>
                </v-row>
                </div>
            </v-col>
            <v-col cols="12" lg="4">
                <app-metadata-resume :sla="getDataTrack($event)" :details="false" :musics="musics = $event"></app-metadata-resume>
            </v-col>
        </v-row>
        <div class="mt-6 d-flex justify-end flex-wrap-reverse">
            <v-btn
                class="mr-4 clear-button button-clear-size"
                outlined
                dark
                color="white"
                height="40"
                @click="$emit('decrement');"
            >
                <span>Voltar</span>
            </v-btn>
        </div>
        <app-notification 
            @notificationStatus="changeNotificationStatus" 
            :active="notification.active"
            :type="notification.type"
            :message="notification.message"    
        >
        </app-notification>
    </div>
</template>

<script>
import moment from 'moment';
import MetadataViewResume from '../components/MetadataViewResume.vue';
import LoadingFull from '../components/LoadingFull.vue';
import Notification from '../components/Notification.vue';

export default {
    components: {
        'app-loading-full': LoadingFull,
        'app-notification': Notification,
        "app-metadata-resume": MetadataViewResume,
    },
    props: {
        album: {
            type: Object,
            required: true
        },
        musics: {
            type: Object,
            required: true
        },
        musicsUploaded: {
            type: Number,
            required: true,
            default: 0
        },
        albumCover: {
            required: true,
            type: String
        },
        albumDetails: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        moment: moment,
        albums:[],
        pos:[],
        headers: [
          { text: ' ', align: 'start', sortable: false, value: 'audio'},
          { text: '#', sortable: false, value: 'order_track' },
          { text: 'Faixa', sortable: false, value: 'faixa' },
          { text: 'Participações', align: 'center', sortable: false, value: 'participations' },
          { text: 'ISRC', sortable: false, value: 'isrc' },
        //   { text: 'Ações', sortable: false, align: 'end', value: 'actions' }
        ],
        onPlay: false,
        loading: false, 
        desc: 'Carregando...',
        notification: {
            active: false,
            message: '',
            type: ''
        } 
    }),
    methods: {
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        },
        getDataTrack() {
            if (this.musics.producerName.length>0) {
                this.albums.push(this.musics)
            }
            console.log(this.albums)
            
        },

        playMusic(index) {
            const audio = document.getElementById('play-' + index);
            if (!this.onPlay) {
                this.onPlay = true;
                audio.play();
            } else {
                this.onPlay = false;
                audio.pause();
            }
        }, 
    }
}
</script>

<style lang="scss" scoped>
    .fluid {
        width: 100%;
    }
    .fluid-height {
        height: 100%;
    }
    .cover-size {
        width: 100%;
        max-width: 400px
    }
    ul {
        list-style: none;
        padding: 0;
        margin-top: 24px;

        li {
            border-bottom: 1px solid white;
            padding-bottom: 12px;

            span {
                color: #994995
            }
        }
    }
    .submit-button {
        max-width: 250px !important;
    }

    .button-clear-size {
        width: 150px;
        border-radius: 12px;
    }
    .disc-cover {
        width: 230px;
        height: 225px;
    }
    .border-bottom {
        padding-bottom: 6px;
        border-bottom: 1px solid #fff;
    }
    .album-title {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.3;
        color: #ffffff;
    }
    .desc-album {
        font-size: 1em;
        text-align: left;
        color: #ffffff;
    }
    .desc-album-opc {
        font-size: 0.75em;
        text-align: left;
        color: #ffffffa1;
    }
    .bg-table {
        background-color: #ffffff00 !important;
    }
    .text-decoration-none {
        text-decoration: none !important;
    }

    @media only screen and (max-width: 600px){
        .submit-button {
            max-width: 100% !important;
        }

        .button-clear-size {
            width: 100% !important;
            border-radius: 12px;
            margin-right: 0px !important;
            margin-top: 24px;
        }
    }
</style>