<template>
  <div>
    <h3 class="white--text">CADASTRAR USUÁRIO</h3>
    <div class="mt-12">
      <app-register-user-form></app-register-user-form>
    </div>
  </div>
</template>

<script>
import RegisterUserFormVue from "../components/RegisterUserForm.vue";

export default {
  components: {
    "app-register-user-form": RegisterUserFormVue,
  },
};
</script>

<style></style>
