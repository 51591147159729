<template>
    <div class="bg-card-content">
        <app-loading :active="loading"></app-loading>
        <app-loading-full :active="loading"></app-loading-full>
        <v-form ref="form" v-model="hableSubmit">
        
            <v-row>
                <v-col cols="12" md="8" lg="12">
                <div class="bg-card-content">
                <v-col cols="12" md="8" lg="4">
                <img src="../assets/hitbel.jpg" alt="" width="100%" v-if="cover.length < 1" class="disc-cover-none"/>
                <div v-else>
                    <img :src="getCoverUrl()" class="relative disc-cover">
                    
                </div>
                </v-col>

                <v-col cols="12" md="6"> 
                    <v-file-input
                        accept="image/jpeg,image/x-png,image/png"
                        placeholder="Adicionar imagem de capa"
                        prepend-icon="mdi-camera"
                        label="ADICIONAR CAPA"
                        v-model="cover"
                        :rules="[rules.required]"
                        @change="setCoverPreview()"
                        :clearable="false"
                    ></v-file-input>
                    </v-col>
                    </div>
                </v-col>
                <v-col cols="12">
                    <h3 class="white--text">DADOS DO USUÁRIO</h3>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="no_first_name" label="Nome" :rules="[rules.required]" required dark
                        class="fluid"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="no_last_name" label="Sobrenome" :rules="[rules.required]" required dark
                        class="fluid"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="nu_phone" label="Telefone" :rules="[rules.required]" required dark
                        class="fluid"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="ds_email" label="e-mail" :rules="[rules.required]" required dark
                        class="fluid"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="ds_address" label="endereço" :rules="[rules.required]" required dark
                        class="fluid"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="no_profile" label="Tipo" :rules="[rules.required]" required dark
                        class="fluid"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-checkbox v-model="is_active" dark :disabled="'disabled'" label="Esse usuário está ativo">
                    </v-checkbox>
                </v-col>
                <!-- <v-col cols="12">
                    <input type="file" class="select-file" @change="onFileSelected" ref="fileInput">
                   
                    <v-btn class="mr-4 clear-button button-upload-file" outlined dark color="white" height="40"
                        @click="onUpload">
                        <span> Upload </span>
                    </v-btn>
                </v-col> -->
            </v-row>
            <div class="mt-6 d-flex justify-end flex-wrap-reverse">
                <v-btn class="mr-4 clear-button button-clear-size" outlined dark color="white" height="40"
                    @click="$router.go(-1)">
                    <span>Voltar</span>
                </v-btn>
                <v-btn
                    :disabled="!hableSubmit"
                    class="submit-button"
                    height="40"
                    @click="putUser()"
                >
                    <span v-if="loading">SALVANDO</span>
                    <span v-else>SALVAR MUDANÇAS</span>
                </v-btn>
            </div>
        </v-form>
        <app-notification @notificationStatus="changeNotificationStatus" :active="notification.active"
            :type="notification.type" :message="notification.message">
        </app-notification>
    </div>
</template>

<script>

import { getUser, putUser } from '../services/User';
import axios from 'axios';
import PageLoading from '../components/PageLoading.vue';
import LoadingFull from '../components/LoadingFull.vue';
import Notification from '../components/Notification.vue';

export default {
    components: {
        'app-loading': PageLoading,
        'app-loading-full': LoadingFull,
        'app-notification': Notification
    },
    data: () => ({
        loading: false,
        params: '',
        avatar_url: '',
        ds_address: '',
        cover: [],
        coverImg: '',
        ds_email: '',
        no_last_name: '',
        no_profile: '',
        nu_phone: '',
        is_active: '',
        id_user: '',
        no_first_name: '',
        primaryGenres: [],
        selectedFile: null,
        secondaryGenres: [],
        languages: [],
        typesOfPoetry: ['Monetização', 'Bloqueio', 'Rastreamento'],
        hableSubmit: false,
        rules: {
            required: value => !!value || 'Este campo é obrigatório.'
        },
        notification: {
            active: false,
            message: '',
            type: ''
        }
    }),
    created() {
        this.getUserById();
    },
    methods: {
        getUserById() {
            this.loading = true;
            const albumResponse = getUser(this.$route.params.id);
            albumResponse
                .then(res => {
                    const { data } = res.data;
                    this.id_user = data.id_user;
                    this.no_first_name = data.no_first_name;
                    this.no_last_name = data.no_last_name;
                    this.ds_email = data.ds_email;
                    this.ds_address = data.ds_address;
                    this.nu_phone = data.nu_phone;
                    this.no_profile = data.user_profiles[0].profile.no_profile;
                    this.is_active = data.user_profiles[0].profile.is_active;
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        putUser() {
            const user = {
                id_user: this.id_user,
                no_first_name: this.no_first_name,
                no_last_name: this.no_last_name,
                ds_email: this.ds_email,
                ds_address: this.ds_address,
                nu_phone: this.nu_phone,
                no_profile: this.no_profile,
                is_active: this.is_active,
            }
            putUser(user)
            .then(() => {
                this.notification = {
                    active: true,
                    message: "Informações atualizadas com sucesso!",
                    type: 'success'
                };
            })
            .catch(() => {
                this.notification = {
                    active: true,
                    message: "Ops! Ocorreu um erro. Tente novamente.",
                    type: 'error'
                }
            })
            .finally(() =>{
                this.loading = false;
                this.hableSubmit = true;
            })
        },
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        },
        onFileSelected(event) {
            this.selectedFile = event.target.files[0]
        },
        setCoverPreview() {
            const coverReader = new FileReader();   
            coverReader.onloadend = () => {
                this.coverImg = coverReader.result;
            }
            if (["image/jpeg", "image/x-png", "image/png"].includes(this.cover.type)) {
                coverReader.readAsDataURL(this.cover);   
            } else {
                this.removeCover();
            }
        },
        removeCover() {
            this.cover = [];
            this.coverImg = '';
        },
        getCoverUrl() {
            return this.coverImg;
        },
        onUpload() {
            const form = new FormData();
            form.append('image', this.selectedFile, this.selectedFile.name)
            axios.post('', form, {
                onUploadProgress: uploadEvent => {
                    console.log('Upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
                }
            }).then(res => {
                console.log(res)
            })
        },
    },
}

</script>

<style scoped>
.bg-card-content {
    background-color: rgb(0 0 0 / 41%);
    padding: 2rem;
    margin-bottom: 30px;
    height: auto;
    width: 100%
}

.border-bottom {
    padding-bottom: 6px;
    border-bottom: 1px solid #fff;
}

.name-profile-edit {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.3;
    color: #eb6573;
}

.desc-profile-edit {
    font-size: 1.25rem;
    text-align: left;
    color: #ffffff;
}

.clear-button {
    width: 156px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.submit-button {
    max-width: 250px !important;
}

.button-clear-size {
    width: 150px;
    border-radius: 12px;
}

@media only screen and (max-width: 600px) {
    .submit-button {
        max-width: 100% !important;
    }

    .button-clear-size {
        width: 100% !important;
        border-radius: 12px;
        margin-right: 0px !important;
        margin-top: 24px;
    }
}

.select-file {
    width: 100px;
    height: 50px;
    position: absolute;
    left: 420px;
    top: 150px;
}

.button-upload-file {
    position: absolute;
    left: 400px;
    top: 200px;
    width: 150px;
    border-radius: 12px;
    transition: background-color .5s;
}

.button-upload-file:hover {
    background-color: #0095ff;
}
</style>