import Vue from 'vue'

export async function postTrack(idAlbum, idVolume, trackData) {
    const response = await Vue.prototype.$httpDist
    .post('/album/'+ idAlbum +'/volume/'+ idVolume +'/track', trackData);
    return response
}


export async function getTrackByAlbum(idAlbum) {
    const response = await Vue.prototype.$httpDist.get('/album/'+ idAlbum + '/volume');
    return response
}

export async function getTrackType() {
    const response = await Vue.prototype.$httpDist.get('/track-version-type');
    return response
}

export async function getTrackFileUrl(idAlbum, idVolume, idTrack) {
    const response = await Vue.prototype.$httpDist
    .get('/album/' + idAlbum + '/volume/' + idVolume + '/track/' + idTrack + '/audiotrack', {
        headers: {
            'File-Content-Type': 'audio/wave'
        }
    });
    return response
}

export async function putTrackFile(url, file) {
    const response = await fetch(url, {
        method: 'PUT', 
        headers: {
            'Content-Type': 'audio/wave',
            'x-api-key': 'mL55l14pPIkigZm90HXc3B94lMd4zeMYa0gRcVc0'
        },
        body: file 
    });
    return response; 
}