<template>
    <div>
        <img v-if="albumCover" :src="albumCover" alt="" class="card-album">
        <img v-else src="../assets/album-none.svg" alt="" class="card-album">
        <div class="pb-6">
            <h3 class="white--text mt-1 limit-text">{{ albumName }}</h3>
            <h4 class="grey--text">{{ albumType }}</h4>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        albumCover: {
            type: String,
            required: false
        },
        albumName: {
            type: String,
            required: true
        },
        albumType: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
    .limit-text {
        white-space: wrap; 
        width: 16ch; 
        overflow: hidden;
        text-overflow: ellipsis; 
    }

    .card-album {
        width: 200px;
        height: 200px;
    }
    @media only screen and (max-width: 960px){
        .card-album {
            width: 150px;
            height: 150px;
        }
    }

    @media only screen and (max-width: 600px){
        .card-album {
            width: 125px;
            height: 125px;
        }
    }
</style>