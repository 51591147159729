<template>
  <div>
    <app-loading-full :active="loading"></app-loading-full>
    <h3 class="white--text mt-6">USUÁRIOS CADASTRADOS</h3>

    <div class="mt-6 d-flex justify-end flex-wrap-reverse">
      <v-btn class="mr-4 clear-button button-clear-size" outlined dark color="white" height="40"
        @click="redirectUserRegisterPage">
        <span>Cadastrar</span>
      </v-btn>
    </div>

    <v-card dark class="mt-4" color="#071c33">
      <v-col md="6" class="mb-4">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar Usuário" single-line hide-details>
        </v-text-field>
      </v-col>
      <v-data-table :loading="loading" loading-text="Carregando..." :headers="headers" :items="data" :search="search"
        item-key="id_user" class="elevation-1 bg-table">
        <template v-slot:item.full_name="{ item }">{{ item.no_first_name }} {{ item.no_last_name }}</template>
        <template v-slot:item.is_active="{ item }">
            <v-icon>
              {{ item.is_active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
            </v-icon>
        </template>

        <template v-slot:item.actions>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn plain v-on="on" @click="openUserSystemAccessModal">
                <v-icon size="16" v-bind="attrs">mdi-lock</v-icon>
              </v-btn>
            </template>
            <span>Liberar Acesso</span>
          </v-tooltip>
        </template>

        <template v-slot:item.actions2="{ item }">
          <router-link :to="'/editar/user/' + item.id_user" class="text-decoration-none">
            <v-icon size="16">mdi-eye</v-icon>
          </router-link>
        </template>
      </v-data-table>
    </v-card>
    <app-notification @notificationStatus="changeNotificationStatus" :active="notification.active"
      :type="notification.type" :message="notification.message">
    </app-notification>
    <app-user-system-access :open="userSystemAccess.open" :userId="userSystemAccess.userId"
      :unlock="userSystemAccess.unlock" @changeDisplayOpen="changeDisplayOpen"></app-user-system-access>
  </div>
</template>

<script>
import Notification from "./Notification";
import LoadingFull from "../components/LoadingFull";
import UserSystemAccess from "../components/UserSystemAccess";
import * as UserService from "../services/User";

export default {
  components: {
    "app-notification": Notification,
    "app-loading-full": LoadingFull,
    "app-user-system-access": UserSystemAccess,
  },
  props: {
    idLabel: {
      required: false,
      type: String,
    },
  },
  created() {
    this.userList();
  },
  data: () => ({
    notification: {
      active: false,
      message: "",
      type: "",
    },
    userSystemAccess: {
      open: false,
      userId: 0,
      unlock: false,
    },
    artists: [],
    loading: false,
    headers: [
      {
        text: "Nome do Usuário",
        value: "full_name",
        sortable: true,
        filterable: true,
      },
      { text: "E-mail", align: "center", value: "ds_email", sortable: true },
      { text: "Acesso", align: "center", value: "is_active", sortable: true, type:"checkbox", width: "50" },
      {
        text: "Ações",
        align: "center",
        value: "actions",
        sortable: false,
        filterable: false,
      },
      { text: 'Ações', align: 'center', value: 'actions2', sortable: false, filterable: false },
    ],
    data: [],
    search: "",
  }),
  methods: {
    userList() {
      this.loading = true;

      UserService.getUsers()
        .then(({ data }) => {
          this.data = data.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeNotificationStatus() {
      this.notification = {
        active: false,
        message: "",
        type: "",
      };
    },
    redirectUserRegisterPage() {
      this.$router.push({ name: "RegisterUser" });
    },
    openUserSystemAccessModal() {
      this.userSystemAccess = {
        open: true,
        userId: 0,
        unlock: false,
      };
    },
    changeDisplayOpen() {
      this.userSystemAccess.open = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-table {
  background-color: #071c33 !important;
}

tbody {
  tr:hover {
    background-color: #041527 !important;
  }
}
</style>
