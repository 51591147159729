<template>
    <v-form
        ref="form"
        v-model="hableSubmit"
    >
        <div class="bg-card-content" v-if="typeUser != 'Artist'">
        <app-loading-full :active="loading"></app-loading-full>
            <h3 class="white--text mb-8 text-uppercase">Seleção de artista</h3>
            <v-row>
                <v-col>
                    <v-autocomplete
                        :disabled="'disabled'"
                        dark
                        v-model="no_label"
                        :items="no_label"
                        :rules="[rules.required]"
                        label="Selecione o selo que o artista pertence"
                        
                    ></v-autocomplete>
                </v-col>
                <v-col>
                    <v-autocomplete
                        :disabled="'disabled'"
                        dark
                        v-model="no_artistic_name"
                        :items="no_artistic_name"
                        item-text="no_artist"
                        item-value="id_artist"
                        :rules="[rules.required]"
                        label="Selecione o artista que você deseja cadastrar o álbum"
                        @change="getArtistData()"
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </div>
        <template>
            <div class="bg-card-content">
                <h3 class="white--text mb-8 text-uppercase">Informações básicas</h3>
                <div>
                    <v-row>
                        <v-col cols="12" md="8" lg="6">
                            <img :src="this.im_artwork" alt="" width="100%" v-if="cover.length < 1" class="disc-cover-none"/>
                            <div v-else>
                                <img :src="getCoverUrl()" class="relative disc-cover">
                                <img src="../assets/disc.png" alt="" class="disc d-none d-md-inline">
                            </div>
                        </v-col> 
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select
                                label="Tipo"
                                :items="type"
                                v-model="type"
                                item-text="type"
                                item-value="type"
                                :rules="[rules.required]"
                                :disabled="'disabled'"
                                required
                                dark
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="no_title"
                                label="Título"
                                :rules="[rules.required]"
                                :disabled="'disabled'"
                                required
                                dark
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="albumTypeSelect == 2">
                                <v-checkbox
                                v-model="hasMultipleArtists"
                                :disabled="'disabled'"
                                label="Esse produto tem a participação de múltiplos artistas"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <div v-if="hasMultipleArtists">
                        <h4 class="white--text mb-4">Participação</h4>
                        <v-row v-for="(participation, index) in participations" :key="index">
                            <v-col cols="12" lg="6">
                                <v-select
                                    label="Tipo de participação"
                                    :items="participationType"
                                    item-text="desc"
                                    item-value="code"
                                    :rules="[rules.required]"
                                    :disabled="'disabled'"
                                    v-model="participation.type"
                                    required
                                    dark
                                ></v-select>
                            </v-col>
                            <v-col cols="12" lg="6">
                                    <v-text-field
                                    label="Artista"
                                    :rules="[rules.required]"
                                    :disabled="'disabled'"
                                    v-model="participation.artist"
                                    required
                                    dark
                                    :append-icon="participations.length > 1 ? 'mdi-minus-circle' : ''"
                                    @click:append="participations.splice(index, 1)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-col cols="12">
                            <div class="d-flex align-center pointer" @click="participations.push({type: '', artist: ''})">
                                <v-icon class="mr-3">
                                    mdi-plus-circle
                                </v-icon>
                                <span class="white--text">Adicionar artista</span>
                            </div>
                        </v-col>
                    </div>
                </div>
            </div>
            <div class="bg-card-content">
                <h3 class="white--text mb-8 text-uppercase">Gênero e Idiomas</h3>
                <div>
                    <v-row>
                        <v-col cols="12" lg="4">
                        <v-select
                            dark
                            v-model="no_genre"
                            :items="no_genre"
                            :disabled="'disabled'"
                            item-text="no_genre"
                            item-value="id_genre"
                            label="Gênero Principal"
                        ></v-select>
                        </v-col>
                        <v-col cols="12" lg="4">
                            <v-select
                                dark
                                v-model="sub_genre"
                                :items="sub_genre"
                                :disabled="'disabled'"
                                item-text="sub_genre"
                                item-value="sub_genre"
                                label="Gênero Secundário"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" lg="4">
                            <v-select
                                dark
                                v-model="no_language"
                                :items="no_language"
                                :disabled="'disabled'"
                                item-text="no_language"
                                item-value="no_language"
                                label="Idioma Principal"
                                :rules="[rules.required]"
                            ></v-select>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div class="bg-card-content">
                <h3 class="white--text mb-8 text-uppercase">Lançamentos e datas</h3>
                <v-row>
                    <v-col cols="12">
                            <v-checkbox
                            v-model="migration"
                            :disabled="'disabled'"
                            label="Esse produto é uma migração"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <div>
                    <v-row>
                        <v-col cols="12" md="6" lg="3">
                            <v-menu
                                v-model="releaseDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                :disabled="'disabled'"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="dt_release"
                                        :label="'Data de Lançamento*'"
                                        readonly
                                        v-bind="attrs"
                                        :disabled="'disabled'"
                                        v-on="on"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="dt_release"
                                    :disabled="'disabled'"
                                    @input="releaseDateMenu = true"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="6" lg="3" v-if="migration">
                            <v-menu
                                v-model="migrationDateMenu"
                                :close-on-content-click="false"
                                :disabled="'disabled'"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="migrationDate"
                                        label="Data de Migração"
                                        :disabled="'disabled'"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="migrationDate"
                                    :disabled="'disabled'"
                                    @input="migrationDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <v-menu
                                v-model="productionDateMenu"
                                :disabled="'disabled'"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="ds_copyright_release"
                                        label="Copyright e Data de Produção"
                                        :disabled="'disabled'"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="ds_copyright_release"
                                    :disabled="'disabled'"
                                    @input="productionDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <v-menu
                                v-model="recordingDateMenu"
                                :disabled="'disabled'"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="ds_copyright_recording"
                                        :disabled="'disabled'"
                                        label="Copyright e Data de Gravação"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="ds_copyright_recording"
                                    :disabled="'disabled'"
                                    @input="recordingDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div class="bg-card-content">
                <h3 class="white--text mb-8 text-uppercase">Código UPC</h3>
                <div>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Código de Barras (UPC)"
                                required
                                :disabled="'disabled'"
                                dark
                                v-model="co_upc"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div class="mt-12 d-flex justify-end">
                <v-btn
                    class="mr-4 clear-button button-clear-size"
                    outlined
                    dark
                    color="white"
                    height="40"
                    @click="$router.go(-1)"
                >
                    <span>Voltar</span>
                </v-btn>
                 <v-btn
                    :disabled="!hableSubmit"
                    class="submit-button"
                    height="40"
                    @click="sendInfo()"
                >
                    <span v-if="loading">Salvando</span>
                    <span v-else>Continuar</span>
                </v-btn>
            </div>
        </template>
    </v-form>
</template>

<script>


import LoadingFull from '../components/LoadingFull.vue';
import { getAlbumByArtist } from "../services/Album";
// import EditLabel from './EditLabel.vue'

export default {
    components: {
        // 'app-card-user': CardUser
        // 'app-notification': Notification,
        'app-loading-full': LoadingFull,
        // 'app-label-edit': EditLabel
    },
    props: {
        idLabel: {
            required: false,
            type: String
        }
    },
    created() {
        this.profileName = localStorage.tp_profile == 'Artist' ? localStorage.no_profile : this.$route.params.name;
        this.idArtist = parseInt(this.$route.params.id);
        this.idAlbum = parseInt(this.$route.params.idAlb);
        this.getArtisAlbums(this.idArtist,this.idAlbum);
        
    },
    data: () => ({
        select: [],
        albumTypeList: [],
        hasMultipleArtists: false,
        hableSubmit: true,
        albumTitle: '',
        typeUser: localStorage.tp_profile,
        migration: false,
        cover: [],
        albumTypeSelect: '',
        coverImg: '',
        participations: [{type: '', artist: ''}],
        participationType: [{code: 8, desc: 'Feat.'}, {code: 5, desc: 'Collab.'}],
        primaryGenres: [],
        secondaryGenres: [],
        mainGenre: '',
        secondaryGenre: '',
        language: '',
        languages: [],
        releaseDateMenu: false,
        releaseDate: '',
        migrationDateMenu: false,
        migrationDate: '', 
        productionDateMenu: false,
        productionDate: '',
        recordingDateMenu: false,
        recordingDate: '', 
        upc: '',
        labels: [],
        labelName: '',
        labelSelected: '',
        artists: [],
        artistSelected: null,
        artistName: '',
        rules: {
            required: value => !!value || 'Este campo é obrigatório.'
        },



        notification: {
            active: false,
            message: '',
            type: ''
        },
        loading: false,
        no_label: '',
        no_artistic_name: '',
        co_upc: '',
        ds_copyright_recording: '',
        ds_copyright_release: '',
        dt_release: '',
        no_genre: '',
        sub_genre: '',
        no_language: '',
        no_title: '',
        type: '',
        im_artwork: '',

    }),
    methods: {
        getArtisAlbums(idArtist, idAlbum) {
            this.loading = true;
            const encodeId = btoa(JSON.stringify({"artist":{"id_artist": idArtist}}));
            const albumResponse = getAlbumByArtist(encodeId);
            albumResponse
                .then(res => {
                    const { data } = res.data;
                    var size = Object.keys(data).length;
                    for (var i = 0; i < size; i++) {
                        if (data[i].id_album == idAlbum) {
                            var pos = i 
                        }   
                    }
                    this.no_label = data[pos].label.no_label;
                    this.no_artistic_name = data[pos].artist.no_artistic_name;
                    this.co_upc = data[pos].co_upc;
                    this.ds_copyright_recording = data[pos].ds_copyright_recording;
                    this.ds_copyright_release = data[pos].ds_copyright_release;
                    this.dt_release = data[pos].dt_release;
                    this.no_genre = data[pos].genre.no_genre;
                    this.sub_genre = data[pos].sub_genre.no_genre;
                    this.no_language = data[pos].language.no_language;
                    this.no_title = data[pos].no_title;
                    this.type = data[pos].type.ds_type;
                    this.im_artwork = data[pos].im_artwork;
                
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            }) 
        },
        setAlbumInfoDetails () {
            const albumInfoDetails = {
                labelName: this.no_label,
                artistName: this.no_artistic_name,
                albumTypeName: this.type,
                mainGenreName: '',
                nameAlbum: this.no_title,
                upcAlbum: this.co_upc,
                albumMembers: []
            }

            // const genre = this.primaryGenres.filter( genre => genre.id_genre == this.mainGenre);
            albumInfoDetails.mainGenreName = this.no_genre;

            // const albumType = this.albumTypeList.filter( albumTypeItem => albumTypeItem.id_album_type == this.albumTypeSelect);
            albumInfoDetails.albumTypeName = this.type;

            // if (albumInfoDetails.albumTypeName == 'SINGLE') {
            //     albumInfoDetails.albumMembers = this.participations
            // }

            return albumInfoDetails
        },
        sendInfo() {
            this.$emit("albumDetails", this.setAlbumInfoDetails())
            this.$emit("albumCover", this.im_artwork);
            this.$emit("increment");
        },
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .bg-table {
        background-color: #071c33 !important;
    }

    tbody {
        tr:hover {
            background-color: #041527 !important;
        }
    }
    .submit-button {
        width: 150px !important;
    }
</style>