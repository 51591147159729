<template>
    <div class="bg-auth d-flex align-center">
        <v-container>
            <v-row>
                <v-col sm="12" md="6" lg="5">
                    <app-header-auth :title="header.title" :description="header.description"></app-header-auth>
                    <app-complete-registration-form-auth></app-complete-registration-form-auth>
                </v-col>
            </v-row>
        </v-container>
        <app-image-background-auth></app-image-background-auth>
    </div>
</template>

<script>
import AuthHeader from '../components/AuthHeader.vue'
import AuthImageBackground from '../components/AuthImageBackground.vue'
import AuthCompleteRegistrationForm from '../components/AuthCompleteRegistrationForm.vue'

export default {
    components: { 
        'app-header-auth': AuthHeader,
        'app-complete-registration-form-auth': AuthCompleteRegistrationForm,
        'app-image-background-auth': AuthImageBackground
    },
    data: () => ({
        header: {
            title: 'Completar Cadastro',
            description: 'Complete os cadastro para ter acesso a plataforma.' 
        }
    })
}
</script>

<style lang="scss">
    @import "../sass/__auth.scss"; 
</style>