<template>
  <div>
    <v-dialog v-model="dialog"  max-width="600px">
      <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="submit-button"
        v-bind="attrs" v-on="on"
        width="300"
        height="40"
        >
          <span>Gerar Relatório</span>
        </v-btn>
      </template>
      <v-card color="#071c33" dark>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <div class="d-flex justify-space-between align-center">
                  <span class="headline white--text">Periodo do relatório</span>
                  <v-icon size="24px" class="pointer" @click="dialog = false">mdi-close</v-icon>
                </div>
              </v-col>
            </v-row>
            <v-form >
              <v-row>
                <v-col >
                  <date-picker 
                    v-model="state.date"
                    type="month"
                    lang="pt"
                    format="MMMM-YY"
                  ></date-picker>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center flex-wrap align-center">
          <v-btn
            :disabled="!valid"
            @click="generateReport"
            class="submit-button"
            width="150"
            height="40"
          >
            <v-progress-circular v-if="loading" :indeterminate="true" size="24"></v-progress-circular>
            <span v-else>Confirmar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { generateReport } from "../services/Report"
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import moment from 'moment'

export default {
   components: {
        DatePicker
    },
  props: {
    label: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    name: '',
    loading: false,
    notification: {
        active: false,
        description: '',
        type: '',
        closeText: ''
    },
    valid: true,
    rules: {
      required: value => !!value || 'Este campo é obrigatório.',
    },
    dialog: false,
    state: {
         date: new Date(2021, 9,  16),
         days: false,
    }
  }),
  methods: {
    generateReport() {
      const date =  moment(this.state.date).format('YYYY-MM-01');

      this.loading = true

      generateReport({ date }).then(() => {
        this.notification = {
          active: true,
          message: 'Relatório gerado com sucesso!',
          type: 'success',
        }
        this.$emit('notification', this.notification )
      })
      .catch(() => {
        this.notification = {
          active: true,
          message: "Houve um erro ao gerar o relatório, tente novamente.",
          type: "error",
        }
        this.$emit("notification", this.notification)
      })
      .finally(() => {
        this.loading = false
        this.valid = true
        this.dialog = false
      });
    }
  }
};
</script>

