<template>
    <div class="notification" v-bind:class="{ 'd-flex': active, 'd-none': !active}">    
        <div class="white notification-card">
            <div>
                <v-progress-linear
                    class="progress-1"
                    :active="true"
                    :reverse="true"
                    v-model="value"
                    absolute
                    top
                    color="#eb6573"
                ></v-progress-linear>
                <v-progress-linear
                    class="progress-2"
                    :active="true"
                    v-model="value"
                    absolute
                    top
                    color="#eb6573"
                ></v-progress-linear>
            </div>
            <div class="d-flex justify-space-between align-center">
                <div class="d-flex align-center">
                    <v-icon v-if="type === 'error'" class="mr-2" size="18" color="#ff5d5d">mdi-alert-circle</v-icon>
                    <v-icon v-if="type === 'success'" class="mr-2" size="18" color="green">mdi-check-circle</v-icon>
                    <span class="font-weight-bold">{{message}}</span>
                </div>
                <v-icon @click="$emit('notificationStatus');" color="black">mdi-close</v-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false,
            required: true
        },
        type: {
            type: String,
            default: 'error',
            required: false
        },
        message: {
            type: String,
            default: 'Ocorreu um erro inesperado.',
            required: false
        }
    },
    data: () => ({
        value: 0,
        interval: 0
    }),
    watch: {
        value (val) {
        if (val < 100) return
            this.value = 0;
            clearInterval(this.interval);
            this.$emit('notificationStatus');
        },
        active(active) {
            if (active) {
                this.startBuffer();
            }
            else this.value = 0;
        }
    },
    beforeDestroy () {
        clearInterval(this.interval);
    },
    methods: {
        startBuffer () {
            if (this.active) {
                this.value = 0;
                clearInterval(this.interval)
    
                this.interval = setInterval(() => {
                    this.value += 1
                }, 80)
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .progress-1 {
        width: 50% !important;
        left: 0 !important; 

        .v-progress-linear__background {
            background: none !important;
        }
    }
    .progress-2 {
        width: 50% !important;
        right: 0 !important;
        left: auto !important;

        .v-progress-linear__background {
            background: none !important;
        }
    }
    .notification {
        width: 100%;
        position: absolute;
        top: 40px;
        left: 0;

        .notification-card {
            max-width: 380px;
            width: 90%;
            padding: 15px 19px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            margin: 0 auto;
            position: relative;

            span {
                font-size: 0.875rem;
                font-weight: bold;
                line-height: 1.36;
                color: #747474;
            }
        }
    }
</style>