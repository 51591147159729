<template>
    <div>
        <app-loading-full :active="loading"></app-loading-full>
        <h3 class="white--text mt-6">SELOS CADASTRADOS</h3>
        <!-- <div class="d-flex flex-wrap mt-3">
            <div v-for="label in labels" :key="label.id_label">
                <app-card-user :userName="label.no_label" class="mr-6 mb-6" :description="label.qt_artists + ' Artistas'" :avatarUrl="label.avatar_irl"></app-card-user>
            </div>
        </div> -->
        <v-card dark class="mt-4" color="#071c33">
            <v-col md="6" class="mb-4">
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar Selo"
                single-line
                hide-details
                ></v-text-field>
            </v-col>
            <v-data-table
                :loading="loading" loading-text="Carregando..."
                :headers="headers"
                :items="desserts"
                :search="search"
                class="elevation-1 bg-table"
            >
                <template v-slot:item.actions="{ item }">
                    <router-link :to="'/selo/' + item.no_label + '/' + encodeId(item.id_label)" class="text-decoration-none">
                        <v-icon size="16">mdi-eye</v-icon>
                    </router-link>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>

// import CardUser from './CardUser.vue'
import { getLabels } from '../services/Label';
import LoadingFull from '../components/LoadingFull.vue';

export default {
    components: {
        // 'app-card-user': CardUser
        'app-loading-full': LoadingFull
    },
    created() {
        this.getLabels();
    },
    data: () => ({
        labels: [],
        loading: false,
        headers:  [
          { text: 'Nome do Selo', value: 'no_label', sortable: true, filterable: true },
          { text: 'Quantidade de Artistas', align: 'center', value: 'qt_artists', sortable: true, filterable: true },
          { text: 'Ações', align: 'center', value: 'actions', sortable: false, filterable: false },
        ],
        desserts: [],
        search: ''
    }),
    methods: {
        getLabels() {
            this.loading = true;
            const labelResponse = getLabels();
            labelResponse
            .then(res => {
                const { data } = res.data;
                this.desserts = data;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            })
        },
        encodeId(id) {
            let idEncode = {"id_label": parseInt(id)};
            idEncode = JSON.stringify(idEncode);
            return btoa(idEncode);
        }
    }
}
</script>

<style lang="scss" scoped>
    .bg-table {
        background-color: #071c33 !important;           
    }
    tbody {
        tr:hover {
            background-color: #041527 !important;
        }
    }
    .text-decoration-none {
        text-decoration: none !important;
    }
</style>