<template>
  <div>
    <app-loading-full :active="loading" :text="'Excluindo usuário, aguarde um instante...'"></app-loading-full>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon size="36" color="grey lighthen-1" v-bind="attrs" v-on="on">mdi-delete</v-icon>
      </template>
      <v-card color="#071c33" dark>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-end align-center">
                  <v-icon size="24px" class="pointer" @click="dialog = false">mdi-close</v-icon>
                </div>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                  <h2 class="white--text">Você deseja realmente excluir este artista?</h2>
              </v-col>
              <v-col cols="12" class="d-flex justify-center mt-5">
                <v-btn
                  v-if="!deleteConfirm"
                  color="#ef5350"
                  outlined
                  class="mb-4"
                  @click="deleteConfirm = true"
                  dark
                >
                  <span>Excluir Artista</span>
                </v-btn>
                <v-btn v-else color="#ef5350" class="mb-4" @click="deleteArtist()" dark>
                  <span>CONFIRMAR EXCLUSÃO</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LoadingFull from '../components/LoadingFull.vue';
import { deleteArtist } from "../services/Artist"
import { logout } from "../services/Auth"

export default {
    components: {
        'app-loading-full': LoadingFull
    },
    props: {
        idArtist: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        deleteConfirm: false,
        loading: false,
        dialog: false
    }),
    methods: {
        deleteArtist() {
            this.loading = true;
            const reqArtist = deleteArtist(this.idArtist);
            reqArtist
            .then(() => {
                if (localStorage.tp_profile != 'Artist') {
                    window.location.href = localStorage.home;
                } else {
                    const logoutResponse = logout();
                    logoutResponse
                    .then(() => {
                        localStorage.clear();
                        window.location.href = './login'
                    })
                    .catch(err => {
                        console.log(err)
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                this.loading = false
            })
        }
    }
};
</script>

<style scoped>
    .submit-button {
        width: 150px !important;
    }
</style>