<template>
    <div>
        <app-loading :active="loading"></app-loading>
        <v-form
        ref="form"
        v-model="hableSubmit"
        >
            <v-text-field
                v-model="login"
                :rules="[rules.required, rules.email]"
                label="Login"
                required
                dark
                class="fluid"
                @keyup.enter="submit"
            ></v-text-field>
            <v-text-field
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min, rules.capitalLetter, rules.number, rules.specialCharacter]"
                :type="show ? 'text' : 'password'"
                name="password"
                dark
                label="Senha"
                @click:append="show = !show"
                class="fluid"
                @keyup.enter="submit"
            ></v-text-field>
            <router-link to="/esqueci-minha-senha" class="navigate-text float-right">Esqueceu sua senha?</router-link>
            <div>
                <v-btn
                    :disabled="!hableSubmit"
                    class="submit-button mt-12"
                    height="60"
                    @click="submit"
                >
                    <span v-if="loading">Entrando</span>
                    <span v-else>Entrar</span>
                </v-btn>
            </div>
        </v-form>
        <app-notification 
            @notificationStatus="changeNotificationStatus" 
            :active="notification.active"
            :type="notification.type"
            :message="notification.message"    
        >
        </app-notification>
    </div>
</template>

<script>
import { postUserCredentials, errorMessage } from '../services/Auth';
import PageLoading from './PageLoading.vue';
import Notification from './Notification.vue';

export default {
    components: { 
        'app-loading': PageLoading,
        'app-notification': Notification
    },
    data: () => ({
        loading: false,
        hableSubmit: true,
        show: false,
        login: '',
        password: '',
        rules: {
            required: value => !!value || 'Este campo é obrigatório.',
            capitalLetter: value =>  /^(?=(?:.*?[A-Z]){1})/.test(value) || 'Sua senha deve conter no mínimo uma letra maiúscula.',
            number: value =>  /^(?=(?:.*?[0-9]){1})/.test(value) || 'Sua senha deve conter no mínimo um caractere alfanumérico.',
            specialCharacter: value =>  /^(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/.test(value) || 'Sua senha deve conter no mínimo um caractere especial.',
            min: v => v.length >= 6 || 'Sua senha precisa ter no mínimo 6 caracteres',
            email: v => /.+@.+\..+/.test(v) || 'E-mail inválido'
        },
        notification: {
            active: false,
            message: '',
            type: ''
        }
    }),
    methods: {
        submit() {
            this.loading = true;
            this.hableSubmit = false;
            if(this.$refs.form.validate()) {
                const authResponse = postUserCredentials(this.login, this.password, null, null);
                authResponse
                .then(res => {
                    const { data } = res.data;
                    let index = 0;
                    if (data.profile.length > 1) {
                        index = 1;
                    }
                    localStorage.id_profile = data.profile[index].id_profile;
                    localStorage.id_profile_entity = data.profile[index].id_profile_entity;
                    localStorage.id_user = data.profile[index].id_user;
                    localStorage.no_profile = data.profile[index].no_profile;
                    localStorage.tp_profile = data.profile[index].tp_profile;

                    switch (data.profile[index].tp_profile) {
                        case 'Client':
                            localStorage.home = '/master';
                            break;
                        
                        case 'Label':
                            localStorage.home = '/selo/'+ localStorage.no_profile + '/' +  btoa(JSON.stringify({"id_label": parseInt(localStorage.id_profile_entity)}));
                            break;
                    
                        case 'Artist':
                            localStorage.home = '/artista/'+ localStorage.no_profile + '/' + localStorage.id_profile_entity;
                            break;
                    }

                    const authResponseProfile = postUserCredentials(this.login, this.password, data.profile[index], data.access_token);

                    authResponseProfile
                    .then(res => { 
                        const { data } = res.data;
                        localStorage.access_token = data.access_token;
                        localStorage.expiration_date = data.expiration_date;

                        window.location.href = localStorage.home;
                    })
                    .catch(err => {
                        this.notification = {
                            active: true,
                            message: errorMessage(err.response.status),
                            type: 'error'
                        }
                    })
                })
                .catch(err => {
                    this.notification = {
                        active: true,
                        message: errorMessage(err.response.status),
                        type: 'error'
                    }
                })
                .finally(() =>{
                    this.loading = false;
                    this.hableSubmit = true;
                })
            }
        },
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        }
    }
}
</script>