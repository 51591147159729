<template>
    <div>
        <app-loading-full :active="loading"></app-loading-full>
        <h3 class="white--text mt-6">ALBUNS CADASTRADOS</h3>
        <div class="mt-6 d-flex justify-end flex-wrap-reverse">
                <v-btn
                    class="submit-button mr-2 mb-4"
                    outlined
                    dark
                    color="white"
                    height="40"
                    @click="$router.go(-1)"
                >
                    <span>Voltar</span>
                </v-btn>
                <v-btn
                    icon href="/cadastro-de-metadados"
                    class="submit-button mr-2 mb-4"
                    outlined
                    dark
                    color="white"
                    height="40"
                >
                    <span>Cadastrar</span>
                </v-btn>
        </div>
        <v-card dark class="mt-4" color="#071c33">
            <v-col md="6" class="mb-4">
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar Artista"
                single-line
                hide-details
                ></v-text-field>
            </v-col>
            <v-data-table
                :loading="loading" loading-text="Carregando..."
                :headers="headers"
                :items="albums"
                :search="search"
                item-key="id_artist"
                class="elevation-1 bg-table"
            >
                <template v-slot:item.actions="{ item }" :id_artist="this.idArtist">
                    <router-link :to="'/data/'+ item.id_album + '/'+ id_artist" class="text-decoration-none">
                        <v-icon size="16">mdi-eye</v-icon>
                    </router-link>
                </template>
            </v-data-table>
        </v-card>
        <app-notification 
            @notificationStatus="changeNotificationStatus" 
            :active="notification.active"
            :type="notification.type"
            :message="notification.message"    
        >
        </app-notification>
    </div>
</template>

<script>

// import CardUser from './CardUser.vue'
import { getArtists } from '../services/Artist'
// import Notification from './Notification.vue';
import LoadingFull from '../components/LoadingFull.vue';
import { getAlbumByArtist } from "../services/Album";
// import EditLabel from './EditLabel.vue'

export default {
    components: {
        // 'app-card-user': CardUser
        // 'app-notification': Notification,
        'app-loading-full': LoadingFull,
        // 'app-label-edit': EditLabel
    },
    props: {
        id_artist: {
            required: false,
            type: String
        }
    },
    created() {
        this.profileName = localStorage.tp_profile == 'Artist' ? localStorage.no_profile : this.$route.params.name;
        this.idArtist = parseInt(this.$route.params.id);
        console.log()
        this.getArtisAlbums(this.idArtist);
    },
    data: () => ({
        notification: {
            active: false,
            message: '',
            type: ''
        },
        artists: [],
        loading: false,
        headers:  [
          { text: 'ID Álbun', align: 'center', value: 'id_album', sortable: true},
          { text: 'Titulo', align: 'center', value: 'no_title', sortable: true},
          { text: 'Data da Criação', align: 'center', value: 'dt_created_at', sortable: true},
        //   { text: 'Status de Entrega', align: 'center', value: 'status', sortable: true},
          { text: 'Ações', align: 'center', value: 'actions', sortable: false, filterable: false },
        ],
        albums: [],
        search: ''
    }),
    methods: {
        getArtists() {
            this.loading = true;
            const artistsResponse = getArtists();
            artistsResponse
            .then(res => {
                const { data } = res.data;
                console.log(data)
                this.desserts = data;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            })
        },
        getArtisAlbums(id) {
            this.loading = true;
            const encodeId = btoa(JSON.stringify({"artist":{"id_artist": id}}));
            const albumResponse = getAlbumByArtist(encodeId);
            albumResponse
            .then(res => {
                const { data } = res.data;
                this.albums = data;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            }) 
        },
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .bg-table {
        background-color: #071c33 !important;
    }

    tbody {
        tr:hover {
            background-color: #041527 !important;
        }
    }
    .submit-button {
        width: 150px !important;
    }
</style>