<template>
    <v-row>
        <v-col cols="12" v-if="!addMusic">
            <div class="bg-card-content">
                <h3 class="white--text">MÚSICAS ADICIONADAS</h3>
                <v-row class="mt-12">
                    <v-col >
                        <div  class="mt-6 d-flex flex-column align-center">
                        <img src="../assets/album-null.png" alt="" width="337px" height="225px" v-if="albumCover == ''"/>
                        
                        <div v-else>
                            <img :src="albumCover" class="relative disc-cover">
                            <img src="../assets/disc.png" alt="" class="disc d-none d-md-inline">
                        </div>
                        </div>
                        <span class="white--text mt-4 highlighted-text" v-if="musics.length == 0">Você ainda não adicionou nenhuma faixa ao seu álbum.</span>
                        <div v-else class="fluid">
                            <div class="mt-6 d-flex flex-column align-center">
                                <span class="white--text mt-4 highlighted-text">Adicione as faixas do seu Álbum!</span>
                                <span class="grey--text mt-1">FORMATO ACEITO: .WAV</span>
                            </div>
                            <div>
                                <ul>
                                    <li v-for="(music, index) in musics" :key="index" >
                                        <div class="d-flex">
                                            <div v-if="music.musicUrl">
                                                <audio :id="'play-' + index" :src="music.musicUrl" type="audio/wav"></audio>
                                                <v-icon size="24" @click="playMusic(index)">mdi-play-circle</v-icon>
                                            </div>
                                            <v-icon size="24" color="#994995" v-else>mdi-music-circle</v-icon>
                                            <span class="ml-2">{{music.no_title}}</span>
                                        </div>
                                        <div class="mt-4 d-flex justify-center">
                                            <app-add-music-file :index="index" :music="music" @setMusicFile="setMusicFile($event)"></app-add-music-file>
                                            <!-- <app-edit-music :music="music" :index="index" class="ml-4"></app-edit-music> -->
                                            <app-delete-music :nameMusic="music.no_title" :index="index" @removeMusic="removeMusic($event)" class="ml-4"></app-delete-music>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="mt-12 d-flex justify-end">
                            <span v-if="albumDetails.albumTypeName == 'SINGLE' && musics.length >= 2" class="info--text mt-6 body-2">SINGLES PODEM CONTER NO MÁXIMO 2 MÚSICAS</span>
                            <v-btn
                                :disabled="albumDetails.albumTypeName == 'SINGLE' && musics.length >= 2"
                                class="submit-button2 mt-6"
                                height="40"
                                @click="addMusic = true;"
                            >
                                <span>Adicionar Nova Música</span>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-10 d-flex justify-end flex-wrap-reverse">
                <v-btn
                    class="mr-4 clear-button button-clear-size mt-2"
                    outlined
                    dark
                    color="white"
                    height="40"
                    @click="$emit('decrement');"
                >
                    <span>Voltar</span>
                </v-btn>
                <v-btn
                    :disabled="musics.length < 1 || tracksAdd.length < musics.length"
                    class="submit-button mt-2"
                    height="40"
                    @click="sendMusics()"
                >
                    <span>SALVAR E CONTINUAR</span>
                </v-btn>
            </div>
        </v-col>
        <v-col cols="12" lg="4" v-if="!addMusic" class="d-none">
            <app-metadata-resume :albumDetails="albumDetails" :albumCover="albumCover" :album="album"></app-metadata-resume>
        </v-col>
        <v-col cols="12" v-else>
            <app-metadata-add-music-form @setAddMusicFalse="setAddMusic($event)" @track="track = $event"></app-metadata-add-music-form>
        </v-col>
    </v-row>
</template>

<script>
import DeleteMusicVue from './DeleteMusic.vue';
import AddMusicFile from './AddMusicFile.vue';
import MetadataAddMusicForm from './MetadataAddMusicForm.vue'
import MetadataResume from './MetadataResume.vue'

export default {
    components: {
        "app-metadata-resume": MetadataResume,
        'app-metadata-add-music-form': MetadataAddMusicForm,
        'app-delete-music': DeleteMusicVue,
        'app-add-music-file': AddMusicFile
    },
    props: {
        album: {
            required: true,
            type: Object
        },
        albumCover: {
            required: true,
            type: String
        },
        albumDetails: {
            required: true,
            type: Object
        }
    },
    data: () => ({
        trackPosition: 1,
        tracksAdd: [],
        addMusic: true,
        onPlay: false,
        track: {},
        musics: []
    }),
    methods: {
        setAddMusic() {
            this.addMusic = false;
            this.track.nu_order_track = this.trackPosition;
            this.musics.push(this.track);
            this.trackPosition++;
        },
        removeMusic(index) {
            this.musics.splice(index, 1);
            this.tracksAdd.splice(index, 1);
        },
        setMusicFile(params) {

            if (!this.tracksAdd[params.index]) {
                this.tracksAdd.push(true);
            }

            const reader = new FileReader();
            reader.onload = (event) => {
                this.musics[params.index].musicUrl = event.target.result;
            }
            reader.readAsDataURL(params.track);
            this.musics[params.index].musicFile = params.track;
        },
        playMusic(index) {
            const audio = document.getElementById('play-' + index);
            if (!this.onPlay) {
                this.onPlay = true;
                audio.play();
            } else {
                this.onPlay = false;
                audio.pause();
            }
        },
        sendMusics() {
            this.$emit("musics", this.musics);
            this.$emit("increment");
        }
    }
}
</script>

<style lang="scss" scoped>
    .fluid {
        width: 100%;
    }
    .cover-size {
        width: 100%;
        max-width: 400px
    }
    .bg-card-content {
        background-color: rgb(0 0 0 / 41%);
        padding: 2rem;
        margin-bottom: 30px;
        height: auto;
    }
    ul {
        list-style: none;
        padding: 0;
        margin-top: 24px;

        li {
            border-bottom: 1px solid white;
            padding-bottom: 12px;

            span {
                color: #994995
            }
        }
    }
    .submit-button {
        width: 100%;
        max-width: 250px !important;
    }
    .submit-button2 {
        width: 100%;
        max-width: 250px !important;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background: linear-gradient(98deg, #994995 0%, #512f87 100%);
        height: 60px;
        opacity : 0.5;

    span {
        color: #fff;
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1.31;
        letter-spacing: 1px;
    }
    }
    .relative {
        position: relative;
    }
    .disc {
        position: absolute;
        height: 226px;
    }
    .button-clear-size {
        width: 150px;
        border-radius: 12px;
    }
    .disc-cover {
        margin-left: -100px;
        width: 230px;
        height: 225px;
    }

    @media (max-width: 960px) {
        .disc-cover {
            margin-left: 0px;
        }
    }

    @media only screen and (max-width: 509px){
        .submit-button {
            max-width: 100% !important;
        }

        .button-clear-size {
            width: 100% !important;
            border-radius: 12px;
            margin-right: 0px !important;
            margin-top: 24px;
        }
    }
</style>