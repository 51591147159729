<template>
    <div>
        <h3 class="white--text mt-6">Relatórios Financeiro</h3>
         <div class="d-flex flex-wrap mt-3 justify-end">
            <template>
                <app-model v-on:notification="notification = $event"></app-model>
            </template>
        </div>
        <v-card dark class="mt-4" color="#071c33">
            <v-col md="6" class="mb-4">
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar Selo"
                single-line
                hide-details
                ></v-text-field>
            </v-col>
            <v-data-table
                v-model="selected"
                :loading="loading"
                loading-text="Carregando..."
                :headers="headers"
                :items="desserts"
                :search="search"
                :single-select="true"
                item-key="id_label"
                show-select
                class="elevation-1 bg-table"
            >
           <template v-slot:[`item.lastModified`]="{ item }">
                <span>{{ new Date(item.lastModified).toLocaleDateString() }}</span>
            </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="downloadReport(item)"
                    >
                        mdi-download
                    </v-icon>
                </template>
            </v-data-table>
            

        </v-card>
        <app-notification 
            @notificationStatus="changeNotificationStatus" 
            :active="notification.active"
            :type="notification.type"
            :message="notification.message"    
        >
        </app-notification>
    </div>
</template>

<script>

import { getReports, downloadReport } from '../services/Report'
import Notification from './Notification.vue';
import ModelReport from './ModelReport.vue'

export default {
    components: {
        'app-notification': Notification,
        'app-model': ModelReport
    },
    created() {
        this.getReport();
    },
    data: () => ({
        notification: {
            active: false,
            message: '',
            type: ''
        },
        labels: [],
        loading: false,
        headers:  [
          { text: 'Relatório', value: 'filename', sortable: true, filterable: true },
          { text: 'Data', align: 'center', value: 'lastModified', sortable: false, filterable: false },
          { text: 'Tamanho do arquivo (bytes)', align: 'center', value: 'size', sortable: true, filterable: true },
          { text: 'Ações', align: 'center', value: 'actions', sortable: false, filterable: false },
        ],
        desserts: [],
        selected: [],
        reports: [],
        search: ''
    }),
    watch: {
        selected: function() {
            this.$emit('labelSelected', this.selected);
        },
        notification: function () {
            this.getReport()
        }
    },
    methods: {
        getReport() {
            this.loading = true;

            getReports()
                .then(({ data }) => (this.desserts = data))
                .catch(err => console.log(err))
                .finally(() => (this.loading = false))
        },
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        },
        downloadReport({ filename }) {
            this.loading = true

            downloadReport({ filename })
                .then(({ data }) => {
                    this.notification = {
                        active: true,
                        message: 'O relatório será baixado em instantes...',
                        type: 'success',
                    }
                    this.$emit('notification', this.notification)

                    window.open(data.url)
                })
                .catch(() => {
                    this.notification = {
                        active: true,
                        message: 'Houve um erro ao tentar baixar o relatório, tente novamente.',
                        type: 'error',
                    }

                    this.$emit('notification', this.notification)
                })
                .finally(() => (this.loading = false))
        }
    }
}
</script>

<style>
    .bg-table {
        background-color: #071c33 !important;
    }
</style>