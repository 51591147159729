<template>
    <v-btn class="logout mt-12" outlined @click="logout"> 
        <span>{{ text }}</span>
        <v-icon class="ml-2" color="#eb6573" size="21">{{ icon }}</v-icon>
    </v-btn>
</template>

<script>
import { logout } from '../services/Auth';

export default {
    props: {
        icon: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        }
    },
    methods:{
        logout(){
            localStorage.clear();
            window.location.href = './login'
            const logoutResponse = logout();
            logoutResponse
            .then(() => {})
            .catch(err => {
                console.log(err)
            })
        }
    }
}
</script>
<style lang="scss" scoped>

.logout {
    border-color: #eb6573;
    border-radius: 20px;
    width: 120px;

    span {
        font-size: 18px;
        line-height: 1.33;
        text-align: center;
        text-transform: capitalize;
        color: #eb6573;
    }
}

</style>