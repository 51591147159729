<template>
    <div>
		<v-progress-linear
			class="progress-1"
			:active="active"
			:reverse="true"
			v-model="value"
			absolute
			top
			color="#eb6573"
		></v-progress-linear>
		<v-progress-linear
			class="progress-2"
			:active="active"
			v-model="value"
			absolute
			top
			color="#eb6573"
		></v-progress-linear>
    </div>
</template>

<script>
export default {
	props: {
		active: {
			type: Boolean,
			default: false,
			required: true
		}
	},
	data: () => ({
		value: 10,
		interval: 0,
	}),

	watch: {
		value (val) {
			if (val < 100) return
			this.value = 0
			this.startBuffer()
		},
		active(active) {
			if (active) this.startBuffer();
			else this.value = 0;
		}
	},

	mounted () {
		this.startBuffer();
	},

	beforeDestroy () {
		clearInterval(this.interval);
		this.value = 0;
	},

	methods: {
		startBuffer () {
			this.value = 10;
			clearInterval(this.interval)

			this.interval = setInterval(() => {
				this.value += 1
			}, 150)
		},
	},
}
</script>

<style lang="scss">
	.progress-1 {
		width: 50% !important;
		left: 0 !important; 
		z-index: 999 !important;

		.v-progress-linear__background {
			background: none !important;
		}
	}
	.progress-2 {
		width: 50% !important;
		right: 0 !important;
		left: auto !important;
		z-index: 999 !important;

		.v-progress-linear__background {
			background: none !important;
		}
	}
</style>