import Vue from 'vue'

export async function getArtists() {
    const response = await Vue.prototype.$httpDist.get('/artist');
    return response
}

export async function getArtistById(id) {
    const response = await Vue.prototype.$httpDist.get('/artist/' + id);
    return response
}

export async function getArtistsByLabel(label) {
    const response = await Vue.prototype.$httpDist.get('/artist/filter/' + label);
    return response
}

export async function postArtist(user, params, labelSelected, userIdLabelArtist) {
    let label = {};

    if (!labelSelected) {
        label = {
            id_aggregator: 1,
            no_label: user.no_first_name,
            user: userIdLabelArtist
        };
    } else {
        label = {
            id_label: labelSelected
        }
    }

    if (user.no_last_name === null) {
        user.no_last_name = '';
    }
    const lastName = user.no_last_name;

    const response = await Vue.prototype.$httpDist
    .post('/artist', {
        no_artistic_name: `${user.no_first_name} ${lastName}`,
        label: label,
        user: userIdLabelArtist ? userIdLabelArtist : user,
        params: JSON.stringify(params)
    });
    return response
}

export async function putArtist(artist, params, idLabel) {
    const label = {
        id_label: idLabel
    };
    const response = await Vue.prototype.$httpDist
    .put('/artist/' + artist.id_artist, {
        id_artist: artist.id_artist,
        no_artistic_name: artist.no_artistic_name,
        label: label,
        params: JSON.stringify(params)
    });
    return response
}

export async function deleteArtist(id) {
    const response = await Vue.prototype.$httpDist
    .delete('/artist/' + id);
    return response
}