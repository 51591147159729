import Vue from 'vue'
import Router from 'vue-router'

import Login from './views/Login.vue'
import RecoverPassword from './views/RecoverPassword.vue'
import RedefinePassword from './views/RedefinePassword.vue'
import CompleteRegistration from './views/CompleteRegistration.vue'

import Main from './views/Main.vue'
import PageMaster from './views/PageMaster.vue'
import PageLabel from './views/PageLabel.vue'
import PageArtist from './views/PageArtist.vue'
import EditArtist from './views/EditArtist.vue'
import UserList from './views/UserList.vue'
import RegisterMetadata from './views/RegisterMetadata.vue'
import moment from 'moment'
import FinancialReport from './views/FinancialReport.vue'
import ListMetadados from './views/ListMetadados.vue'
import ViewMetadata from './views/ViewMetadata.vue'
import PageGetAlbunId from './views/PageGetAlbunId.vue'
import UserRegister from './views/UserRegister'
import TrackData from './views/TrackData.vue'
import UserProfile from './views/UserProfile.vue'
import ProfileEdit from './views/ProfileEdit.vue'
// import ErrorNotFound from './views/ErrorNotFound.vue'
// import ErrorInternalServer from './views/ErrorInternalServer.vue'
// import ErrorForbidden from './views/ErrorForbidden.vue'
// import ErrorBadGateway from './views/ErrorBadGateway.vue'

Vue.use(Router)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login - Hitbel Metadados'
    }
  },
  {
    path: '/esqueci-minha-senha',
    name: 'Recover Password',
    component: RecoverPassword,
    meta: {
      title: 'Esqueci minha senha - Hitbel Metadados'
    }
  },
  {
    path: '/redefinir-senha/:token',
    name: 'Redefine Password',
    component: RedefinePassword,
    meta: {
      title: 'Redefinir senha - Hitbel Metadados'
    }
  },
  {
    path: '/completar-cadastro/:token/:id',
    name: 'Complete Registration',
    component: CompleteRegistration,
    meta: {
      title: 'Completar Cadastro - Hitbel Metadados'
    }
  },
  {
    path: '/',
    name: 'Main',
    component: Main,
    redirect: { path: localStorage.home },
    children: [
      {
        path: '/master',
        name: 'Page Master',
        component: PageMaster,
        meta: {
          title: 'Admin - Hitbel Metadados'
        }
      },
      {
        path: '/selo/:name/:id',
        name: 'Page Label',
        component: PageLabel,
        meta: {
          title: 'Selo - Hitbel Metadados'
        }
      },
      {
        path: '/artista/:name/:id',
        name: 'Page User',
        component: PageArtist,
        meta: {
          title: 'Artista - Hitbel Metadados'
        }
      },
      {
        path: '/editar/artista/:id',
        name: 'Page User Edit',
        component: EditArtist,
        meta: {
          title: 'Editar Artista - Hitbel Metadados'
        }
      },
      {
        path: '/usuarios',
        name: 'Users',
        component: UserList,
        meta: {
          title: 'Usuários - Hitbel Metadados'
        }
      },
      {
        path: '/cadastro-usuario',
        name: 'RegisterUser',
        component: UserRegister,
        meta: {
          title: 'Cadastrar Usuário - Hitbel Metadados'
        }
      },
      {
        path: '/cadastro-de-metadados',
        name: 'Register Metadata',
        component: RegisterMetadata,
        meta: {
          title: 'Cadastrar Metadados - Hitbel Metadados'
        }
      },
      {
        path: '/relatorio-financeiro',
        name: 'Financial Report',
        component: FinancialReport,
        meta: {
          title: 'Gerar Relatorio - Hitbel Financeiro'
        }
      },
      {
        path: '/listar-metadados',
        name: 'List Metadados',
        component: ListMetadados,
        meta: {
          title: 'Listar Metadatas - Hitbel List Metadados'
        }
      },
      {
        path: '/data/:idAlb/:id',
        name: 'Data Metadata',
        component: ViewMetadata,
        meta: {
          title: 'Listar Metadados - Hitbel Metadados'
        }
      },
      {
        path: '/getalbunid/:name/:id',
        name: 'Data Metadata',
        component: PageGetAlbunId,
        meta: {
          title: 'Listar Metadados - Hitbel Metadados'
        }
      },
      {
        path: '/track/data/:id',
        name: 'Track Metadata',
        component: TrackData,
        meta: {
          title: 'Listar Tracks - Hitbel Metadados'
        }
      },
      {
        path: '/pegar/user/:id',
        name: 'Page Profile',
        component: UserProfile,
        meta: {
          title: 'Profile - Hitbel Profile'
        }
      },
      {
        path: '/editar/user/:id',
        name: 'Page Profile Edit',
        component: ProfileEdit,
        meta: {
          title: 'Profile Edit - Hitbel Edit Profile'
        }
      },
      
    ]
  }
]

const router = new Router({
  mode: 'history',
  routes: routes
})


function routeValidation(routeName) {

  let validRoute;
  const routesNoAuth = ["Login", "Recover Password", "Redefine Password", "Complete Registration"];

  const needAuth = routesNoAuth.indexOf(routeName) == -1;
  const isAuthenticated = tokenValidation();

  if (!needAuth && !isAuthenticated) {
    validRoute = true;
  } else {
    validRoute = isAuthenticated;
  }

  return validRoute
}

function tokenValidation() {

  let isValid = false;
  const isAuthenticated = localStorage.access_token ? true : false;

  if (isAuthenticated) {
    isValid = moment(localStorage.expiration_date).format() > moment().format()
  }

  return (isAuthenticated && isValid) ? true : false;
}

router.beforeEach((to, from, next) => {
  if (!routeValidation(to.name))
    next({ name: 'Login' })
  else if (to.name == 'Login' && tokenValidation())
    next({ name: 'Main' })
  else
    next()
})

export default router;