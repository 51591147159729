<template>
    <div>
        <app-profile-header :profileName="profileName" :profileType="profileType" :options="true" :idLabel="idLabelDecode"></app-profile-header>
        <app-list-artists :id_artist="id_artist"></app-list-artists>
    </div>
</template>

<script>
import GetAlbunId from '../components/GetAlbunId.vue'


export default {
    components: {
        
        'app-list-artists': GetAlbunId
    },
    created() {
        this.profileName = localStorage.tp_profile == 'Label' ? localStorage.no_profile : this.$route.params.name;
        this.id_artist = this.$route.params.id;
        const labelDecode = JSON.parse(atob(this.$route.params.id));
        this.idLabelDecode = labelDecode.id_label;
    },
   
}
</script>

<style>

</style>