<template>
    <div class="bg-card-content">
        <app-loading :active="loading"></app-loading>
        <app-loading-full :active="loading"></app-loading-full>
        <v-form ref="form" v-model="hableSubmit">
            
                    <div>
                    <v-row>
                        <v-col cols="12" md="8" lg="12">
                        <div class="bg-card-content">
                            <v-col cols="12" md="8" lg="4">
                            <img src="../assets/hitbel.jpg" alt="" width="100%" v-if="cover.length < 1" class="disc-cover-none"/>
                            <div v-else>
                                <img :src="getCoverUrl()" class="relative disc-cover">
                                
                            </div>
                        </v-col>
                         <!-- <div class="mt-12 d-flex justify-end">
                        <v-col cols="12" lg="6" md="4" >
                            <h4 class="white--text mb-2">Requerimentos para a arte de capa</h4>
                            <ul>
                                <li>Formato do arquivo: JPEG ou PNG</li>
                                <li>Tamanho: pelo menos 600x600 pixels</li>
                                <li>Modo de cor: RGB</li>
                                <li>Sua arte não pode conter logos</li>
                                <li>Sua arte não pode conter endereço de sites</li>
                                <li>Sua arte não pode conter datas ou anúncios</li>
                            </ul>
                        </v-col>
                        </div> -->
                        <v-col cols="12" md="6"> 
                            <v-file-input
                                accept="image/jpeg,image/x-png,image/png"
                                placeholder="Adicionar imagem de capa"
                                prepend-icon="mdi-camera"
                                label="ADICIONAR CAPA"
                                v-model="cover"
                                :rules="[rules.required]"
                                @change="setCoverPreview()"
                                :clearable="false"
                            ></v-file-input>
                        </v-col>
                        </div>
                        </v-col>
                    </v-row>
                    </div>
            <v-row>
                <v-col cols="12">
                    <h3 class="white--text">PERFIL DO USUÁRIO</h3>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="no_first_name" label="Nome" :disabled="'disabled'" :rules="[rules.required]" required dark
                        class="fluid"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="no_last_name" label="Sobrenome" :disabled="'disabled'" :rules="[rules.required]" required dark
                        class="fluid"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="nu_phone" label="Telefone" :disabled="'disabled'" :rules="[rules.required]" required dark
                        class="fluid"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="ds_email" label="e-mail" :disabled="'disabled'" :rules="[rules.required]" required dark
                        class="fluid"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="ds_address" label="endereço" :disabled="'disabled'" :rules="[rules.required]" required dark
                        class="fluid"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="no_profile" label="Tipo" :disabled="'disabled'" :rules="[rules.required]" required dark
                        class="fluid"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-checkbox v-model="is_active" :disabled="'disabled'" dark label="Esse usuário está ativo">
                    </v-checkbox>
                </v-col>
                <!-- <v-col cols="12">
                    <input type="file" class="select-file" @change="onFileSelected" ref="fileInput"> -->
                    <!-- <button @click="$refs.fileInput.click()"> Pick file </button> -->
                    <!-- <v-btn class="mr-4 clear-button button-upload-file" outlined dark color="white" height="40"
                        @click="onUpload">
                        <span> Upload </span>
                    </v-btn> -->
                <!-- </v-col> -->
            </v-row>
            <div class="mt-6 d-flex justify-end flex-wrap-reverse">
                <v-btn class="mr-4 clear-button button-clear-size" outlined dark color="white" height="40"
                    @click="$router.go(-1)">
                    <span>Voltar</span>
                </v-btn>
                <!-- <v-btn
                    :disabled="!hableSubmit"
                    class="submit-button"
                    height="40"
                    @click="putArtist()"
                >
                    <span v-if="loading">SALVANDO</span>
                    <span v-else>SALVAR MUDANÇAS</span>
                </v-btn> -->
            </div>
        </v-form>
        <app-notification @notificationStatus="changeNotificationStatus" :active="notification.active"
            :type="notification.type" :message="notification.message">
        </app-notification>
    </div>
</template>

<script>
import { getUser } from '../services/User';
import axios from 'axios';
import PageLoading from '../components/PageLoading.vue';
import LoadingFull from '../components/LoadingFull.vue';
import Notification from '../components/Notification.vue';

export default {
    components: {
        'app-loading': PageLoading,
        'app-loading-full': LoadingFull,
        'app-notification': Notification,
    },
    data: () => ({
        loading: false,
        params: '',
        avatar_url: '',
        ds_address: '',
        ds_email: '',
        no_last_name: '',
        no_profile: '',
        nu_phone: '',
        is_active: '',
        no_first_name: '',
        primaryGenres: [],
        secondaryGenres: [],
        cover: [],
        coverImg: '',
        selectedFile: null,
        languages: [],
        typesOfPoetry: ['Monetização', 'Bloqueio', 'Rastreamento'],
        hableSubmit: false,
        rules: {
            required: value => !!value || 'Este campo é obrigatório.'
        },
        notification: {
            active: false,
            message: '',
            type: ''
        },
    }),
    created() {
        this.getUserById();
    },
    methods: {
        getUserById() {
            this.loading = true;
            const albumResponse = getUser(this.$route.params.id);
            albumResponse
                .then(res => {
                    const { data } = res.data;
                    this.no_first_name = data.no_first_name;
                    this.no_last_name = data.no_last_name;
                    this.ds_email = data.ds_email;
                    this.ds_address = data.ds_address;
                    this.nu_phone = data.nu_phone;
                    this.no_profile = data.user_profiles[0].profile.no_profile;
                    this.is_active = data.user_profiles[0].profile.is_active;
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        },
        onFileSelected(event) {
            this.selectedFile = event.target.files[0]
        }, 
        setCoverPreview() {
            const coverReader = new FileReader();   
            coverReader.onloadend = () => {
                this.coverImg = coverReader.result;
            }
            if (["image/jpeg", "image/x-png", "image/png"].includes(this.cover.type)) {
                coverReader.readAsDataURL(this.cover);   
            } else {
                this.removeCover();
            }
        },
        removeCover() {
            this.cover = [];
            this.coverImg = '';
        },
        getCoverUrl() {
            return this.coverImg;
        },
        onUpload() {
            const form = new FormData();
            form.append('image', this.selectedFile, this.selectedFile.name)
            axios.post('', form, {
                onUploadProgress: uploadEvent => {
                    console.log('Upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
                }
            }).then(res => {
                console.log(res)
            })
        },
    },
}
</script>

<style scoped lang="scss">
    .bg-card-content {
        background-color: rgb(0 0 0 / 41%);
        padding: 2rem;
        margin-bottom: 30px;
        height: auto;
    }
    ul {
        padding-left: 14px;

        li {
            font-size: 0.875rem;
            color: white;
        }
    }
    .fluid-div {
        width: 100%;
    }
    .pointer {
        cursor: pointer;
    }
    .cover-button {
        width: 70%;
        min-width: 158px !important;
        border-radius: 100px !important;
    }
    .relative {
        position: relative;
    }
    .disc {
        position: absolute;
        height: 226px;
    }
    .disc-cover-none {
        max-width: 337px;
        max-height: 225px;
    }
    .disc-cover {
        width: 230px;
        height: 225px;
    }
    .submit-button {
        max-width: 250px !important;
    }
</style>