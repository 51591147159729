<template>
    <div>
        <app-loading :active="loading"></app-loading>
        <v-form
        ref="form"
        v-model="hableSubmit"
        >
            <v-text-field
                v-model="email"
                :rules="[rules.required, rules.email]"
                label="Email"
                required
                dark
                class="fluid"
            ></v-text-field>
            <div class="d-flex align-center flex-column">
                <v-btn
                    :disabled="!hableSubmit"
                    dark
                    class="white--text submit-button mt-6 mb-2"
                    large
                    @click="submit"
                >
                    <v-progress-circular v-if="loading" :indeterminate="true" size="24"></v-progress-circular>
                    <span v-else>Enviar</span>
                </v-btn>
                <router-link to="/login" class="mt-2 navigate-text">Voltar para o login</router-link>
            </div>
        </v-form>
        <app-notification 
            @notificationStatus="changeNotificationStatus" 
            :active="notification.active"
            :type="notification.type"
            :message="notification.message"    
        >
        </app-notification>
    </div>
</template>

<script>
// import { postUserCredentials } from '../services/Auth';
import { recoverPassword } from '../services/Auth'
import PageLoading from './PageLoading.vue';
import Notification from './Notification.vue';
export default {
    components: { 
        'app-loading': PageLoading,
        'app-notification': Notification
    },
    data: () => ({
        loading: false,
        hableSubmit: true,
        email: '',
        rules: {
            required: value => !!value || 'Este campo é obrigatório.',
            email: v => /.+@.+\..+/.test(v) || 'E-mail inválido'
        },
        notification: {
            active: false,
            message: '',
            type: ''
        }
    }),
    methods: {
        submit() {
            this.loading = true;
            this.hableSubmit = false;
            if(this.$refs.form.validate()) {
                const authResponse = recoverPassword(this.email);
                authResponse.then(() => {
                    this.notification = {
                        active: true,
                        message: 'Um link para recuperação de senha foi enviado para seu email!',
                        type: 'success'
                    };
                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 2000)
                })
                .catch(() => {
                    this.notification = {
                        active: true,
                        message: 'Erro ao enviar email para recuperação de senha. Por favor, tente novamente em um instante.',
                        type: 'error'
                    }
                })
                .finally(() =>{
                    this.loading = false;
                    this.hableSubmit = true;
                })
            }
        },
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        }
    }
}
</script>