<template>
    <div class="bg-card-content heigth-adjust">
        <div v-if="details">
            <h4 class="white--text mb-8 text-uppercase border-bottom">Resumo da publicação</h4>
            <v-row class="mt-6">
                <v-col cols="12" class="d-flex justify-center">
                    <img :src="albumCover" class="disc-cover">
                </v-col>
                <v-col cols="12">
                    <div class="border-bottom">
                        <span class="album-title">{{album.no_title}}</span>
                    </div>
                    <div class="d-flex flex-column mt-4">
                        <span class="mt-2 desc-album">Artista: {{albumDetails.artistName}}</span>
                        <span class="mt-2 desc-album">Selo: {{albumDetails.labelName}}</span>
                        <span class="mt-2 desc-album">Tipo: {{albumDetails.albumTypeName}}</span>
                        <span class="mt-2 desc-album">Gênero Principal: {{albumDetails.mainGenreName}}</span>
                        <span class="mt-2 desc-album">Data de lançamento: {{moment(album.dt_release ? album.dt_release : album.dt_original_release).utc().format('DD/MM/YYYY')}}</span>
                        <span class="mt-2 desc-album">UPC: {{album.co_upc}}</span>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <h3 class="white--text mb-8 text-uppercase border-bottom">Créditos do álbum</h3>
            <v-row>
                <v-col cols="12" class="border-bottom">
                    <div class="desc-album">Produtores</div>
                    <div class="mt-4 desc-album-opc">
                        <v-row>
                            <v-col cols="12" class="mt-2">
                                {{ musics.producerName }}
                             </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col cols="12" class="mt-2">
                    <div class="desc-album">Compositores - Editoras</div>
                    <div class="mt-4 desc-album-opc">
                        <v-row cols="12" md="6">
                           <v-col cols="12" class="mt-2">
                                {{ musics.composerName }} - {{ musics.publisherName }}
                             </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    watch: {
        musics: function () {
            this.filterParticipations()
        }
    },
    props: {
        details: {
            required: false,
            type: Boolean,
            default: true
        },
        albumCover: {
            required: false,
            type: String
        },
        album: {
            required: false,
            type: Object
        },
        albumDetails: {
            required: false,
            type: Object
        },
        musics: {
            required: false,
            type: Object
        }
    },
    data: () => ({
        moment: moment,
        credits: {
            producers: [],
            composers: []
        },
        musics: {
            producerName:'',
            composerName:'',
            publisherName:'',
        },
    }),
    methods: {
        filterParticipations() {
            this.credits = {
                producers: [],
                composers: []
            }
            this.credits.composers.push(this.music.no_producer);
    
            this.credits.producers.push(this.music.no_composer);  
           
        }
    }
}
</script>

<style scoped>
    .bg-card-content {
        background-color: rgb(0 0 0 / 41%);
        padding: 2rem;
        margin-bottom: 30px;
        height: auto;
    }
    .heigth-adjust {
        height: 100% !important;
    }
    .border-bottom {
        border-bottom: 1px solid white;
        padding-bottom: 16px;
    } 
    .disc-cover {
        max-width: 180px;
        max-height: 175px;
        width: 100%;
        height: 100%;
    }
    .album-title {
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1.3;
        color: #ffffff;
    }
    .desc-album {
        font-size: 1em;
        text-align: left;
        font-weight: bold;
        color: #ffffff;
    }
    .desc-album-opc {
        font-size: 0.75em;
        text-align: left;
        color: #ffffffa1;
    }
</style>