import Vue from 'vue'

export async function getAlbumType() {
    const response = await Vue.prototype.$httpDist.get('/album-type');
    return response
}

export async function getAlbumByArtist(idArtist) {
    const response = await Vue.prototype.$httpDist.get('/album/filter/' + idArtist);
    return response
}

export async function postAlbum(albumData) {
    const response = await Vue.prototype.$httpDist
    .post('/album', albumData);
    return response
}

export async function putAlbumDelivery(idAlbum) {
    const response = await Vue.prototype.$httpDist
    .put('/album/' + idAlbum + '/delivery');
    return response
}

export async function getAlbumArtworkUrl(idAlbum) {
    const response = await Vue.prototype.$httpDist
    .get('/album/' + idAlbum + '/artwork', {
        headers: {
            'File-Content-Type': 'image/jpeg'
        }
    });
    return response
}

export async function putAlbumArtwork(url, img) {
    const response = await fetch(url, {
        method: 'PUT', 
        headers: {
            'Content-Type': 'image/jpeg',
            'x-api-key': 'mL55l14pPIkigZm90HXc3B94lMd4zeMYa0gRcVc0'
        },
        body: img 
    });
    return response; 
}

export async function deleteAlbum(idAlbum) {
    const response = await Vue.prototype.$httpDist
    .delete('/album/' + idAlbum);
    return response
}