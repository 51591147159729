<template>
    <div>
        <app-loading-full :active="loading"></app-loading-full>
        <h3 class="white--text mt-6">Informações da Track</h3>
        <div class="mt-6 d-flex justify-end flex-wrap-reverse">
                <v-btn
                    class="submit-button mr-2 mb-4"
                    outlined
                    dark
                    color="white"
                    height="40"
                    @click="$router.go(-1)"
                >
                    <span>Voltar</span>
                </v-btn>
                <v-btn
                    icon href="/cadastro-de-metadados"
                    class="submit-button mr-2 mb-4"
                    outlined
                    dark
                    color="white"
                    height="40"
                >
                    <span>Cadastrar</span>
                </v-btn>
        </div>
        <v-card dark class="mt-4" color="#071c33">
            <v-col md="6" class="mb-4">
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar Artista"
                single-line
                hide-details
                ></v-text-field>
            </v-col>
            <v-data-table
                :loading="loading" loading-text="Carregando..."
                :headers="headers"
                :items="albums"
                :search="search"
                item-key="id_artist"
                class="elevation-1 bg-table"
            >
                <template v-slot:item.actions="{ item }">
                    <router-link :to="'/track/data/'+ item.no_artistic_name + '/'+ item.id_artist" class="text-decoration-none">
                        <v-icon size="16">mdi-eye</v-icon>
                    </router-link>
                </template>
            </v-data-table>
        </v-card>
        <app-notification 
            @notificationStatus="changeNotificationStatus" 
            :active="notification.active"
            :type="notification.type"
            :message="notification.message"    
        >
        </app-notification>
    </div>
</template>

<script>

// import CardUser from './CardUser.vue'
import { getArtists } from '../services/Artist'
// import Notification from './Notification.vue';
import LoadingFull from '../components/LoadingFull.vue';
import { getTrackByAlbum } from "../services/Track";
// import EditLabel from './EditLabel.vue'

export default {
    components: {
        // 'app-card-user': CardUser
        // 'app-notification': Notification,
        'app-loading-full': LoadingFull,
        // 'app-label-edit': EditLabel
    },
    props: {
        idLabel: {
            required: false,
            type: String
        }
    },
    created() {
        this.profileName = this.$route.params.name;
        this.idArtist = parseInt(this.$route.params.id);
        this.getTracksAlbums(this.idArtist);
    },
    data: () => ({
        notification: {
            active: false,
            message: '',
            type: ''
        },
        artists: [],
        loading: false,
        headers:  [
        //   { text: 'Nome do Artista', value: 'artist.no_artistic_name', sortable: true, filterable: true },
          { text: 'ID Volume', align: 'center', value: 'id_album_volume', sortable: true},
          { text: 'Nome do Volume', align: 'center', value: 'no_album_volume', sortable: true},
          { text: 'ID Track', align: 'center', value: 'tracks[0].id_track', sortable: true},
          { text: 'Cont. Explicito', align: 'center', value: 'tracks[0].is_explicit', sortable: true},
           { text: 'Intrumental', align: 'center', value: 'tracks[0].is_instrumental', sortable: true},
          { text: 'Título', align: 'center', value: 'tracks[0].no_title', sortable: true},
          { text: 'Tipo Track', align: 'center', value: 'tracks[0].version_type.ds_track_version_type', sortable: true},
        //   { text: 'Letra da Música', align: 'center', value: 'tracks[0].ds_lyric', sortable: true},
          { text: 'ID Gravação', align: 'center', value: 'tracks[0].recording.id_recording', sortable: true},
          { text: 'ISRC', align: 'center', value: 'tracks[0].recording.co_isrc', sortable: true},
        //   { text: 'Gênero', align: 'center', value: 'co_isrc', sortable: true},
          { text: 'ID Composição', align: 'center', value: 'tracks[0].recording.composition.id_composition', sortable: true},
          { text: 'Gênero', align: 'center', value: 'tracks[0].genre.no_genre', sortable: true},
          { text: 'Sub Gênero', align: 'center', value: 'tracks[0].sub_genre.no_genre', sortable: true},
          { text: 'Linguagem', align: 'center', value: 'tracks[0].language.no_language', sortable: true},
        //    { text: 'Arquivo da Música', align: 'center', value: 'tracks[0].ds_audiotrack', sortable: true},
           
        //   { text: 'Titulo', align: 'center', value: 'tracks[0].version_type.ds_track_version_type', sortable: true},
        //   { text: 'Ações', align: 'center', value: 'actions', sortable: false, filterable: false },
        ],
        albums: [],
        search: ''
    }),
    methods: {
        getArtists() {
            this.loading = true;
            const artistsResponse = getArtists();
            artistsResponse
            .then(res => {
                const { data } = res.data;
                console.log(data)
                this.desserts = data;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            })
        },
        getTracksAlbums(id) {
            console.log(id)
            this.loading = true;
            // const encodeId = btoa(JSON.stringify({"album":{"id_album": 1229}}));
            const albumResponse = getTrackByAlbum(id);
            albumResponse
            .then(res => {
                const { data } = res.data;
                this.albums = data;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            }) 
        },
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .bg-table {
        background-color: #071c33 !important;
    }

    tbody {
        tr:hover {
            background-color: #041527 !important;
        }
    }
    .submit-button {
        width: 150px !important;
    }
</style>