<template>
    <div>
        <app-profile-header></app-profile-header>
        <v-row>
            <v-col v-if="profileType == 'Client'">
                <app-list-labels  v-on:labelSelected="labelSelected = $event"></app-list-labels>
            </v-col>
            <v-col v-if="profileType == 'Label' || idLabel">
                <app-list-artists :idLabel="idLabel"></app-list-artists>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ReportList from '../components/ReportList.vue'
import ListReports from '../components/ListReports.vue'
// import ProfileHeader from "../components/ProfileHeader"

export default {
    components: {
        // 'app-profile-header': ProfileHeader,
        'app-list-labels': ReportList,
        'app-list-artists': ListReports
    },
    data: () => ({
        profileType: localStorage.tp_profile,
        labelSelected: '',
        idLabel: ''
    }),
    watch: {
        labelSelected: function() {
            this.idLabel = '';
            if (this.labelSelected.length > 0) {
                const encodeId = btoa(JSON.stringify({"id_label": parseInt(this.labelSelected[0].id_label)}));
                this.idLabel = encodeId;
            }
        }
    }
}
</script>

<style>

</style>