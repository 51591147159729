var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-loading-full',{attrs:{"active":_vm.loading,"text":_vm.desc}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('div',{staticClass:"bg-card-content"},[_c('h3',{staticClass:"white--text"},[_vm._v("DETALHES DA PUBLICAÇÃO")]),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"5","xl":"4"}},[_c('img',{attrs:{"src":_vm.albumCover,"width":"100%","height":"100%"}})]),_c('v-col',{attrs:{"cols":"12","sm":"8","lg":"7","xl":"8"}},[_c('div',{staticClass:"border-bottom"},[_c('span',{staticClass:"album-title"},[_vm._v(_vm._s(_vm.album.no_title))])]),_c('div',{staticClass:"d-flex flex-column desc-album mt-4"},[_c('span',[_vm._v("Artista: "+_vm._s(_vm.albumDetails.artistName))]),_c('span',[_vm._v("Selo: "+_vm._s(_vm.albumDetails.labelName))]),_c('span',[_vm._v("Tipo: "+_vm._s(_vm.albumDetails.albumTypeName))])]),_c('div',{staticClass:"desc-album-opc d-flex flex-column mt-12"},[_c('span',[_vm._v("Gênero Principal: "+_vm._s(_vm.albumDetails.mainGenreName))]),_c('span',[_vm._v("Data de lançamento: "+_vm._s(_vm.moment(_vm.album.dt_release ? _vm.album.dt_release : _vm.album.dt_original_release).utc().format('DD/MM/YYYY')))]),_c('span',[_vm._v("UPC: "+_vm._s(_vm.album.co_upc))])])])],1),_c('div',{staticClass:"border-bottom mt-6"},[_c('h3',{staticClass:"white--text"},[_vm._v("FAIXAS DO ÁLBUM")])]),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"bg-table",attrs:{"headers":_vm.headers,"items":_vm.musics,"items-per-page":25,"hide-default-footer":"","dark":"","dense":""},scopedSlots:_vm._u([{key:"item.order_track",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}},{key:"item.audio",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',[_c('audio',{attrs:{"id":'play-' + index,"src":item.musicUrl,"type":"audio/wav"}}),_c('v-icon',{attrs:{"size":"24","color":"#994995"},on:{"click":function($event){return _vm.playMusic(index)}}},[_vm._v("mdi-play-circle")])],1)]}},{key:"item.participations",fn:function(ref){
var item = ref.item;
return _vm._l((item.participations),function(participation,index){return _c('span',{key:index},[(participation.type)?_c('span',[_vm._v(" "+_vm._s(participation.type == 5 ? 'Collab.' : 'Feat.')+" "+_vm._s(participation.artist)+" "+_vm._s((index + 1) == item.participations.length ? '' : ' / ')+" ")]):_c('span',[_vm._v(" -- ")])])})}},{key:"item.isrc",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.recording.co_isrc))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('app-delete-music',{attrs:{"nameMusic":item.no_title,"index":index},on:{"removeMusic":function($event){return _vm.removeMusic($event)}}})]}}])})],1)],1)],1)]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('app-metadata-resume',{attrs:{"details":false,"musics":_vm.musics}})],1)],1),_c('div',{staticClass:"mt-6 d-flex justify-end flex-wrap-reverse"},[_c('v-btn',{staticClass:"mr-4 clear-button button-clear-size",attrs:{"outlined":"","dark":"","color":"white","height":"40"},on:{"click":function($event){return _vm.$emit('decrement');}}},[_c('span',[_vm._v("Voltar")])]),_c('v-btn',{staticClass:"submit-button",attrs:{"height":"40"},on:{"click":function($event){return _vm.createAlbum()}}},[_c('span',[_vm._v("Cadastrar")])])],1),_c('app-notification',{attrs:{"active":_vm.notification.active,"type":_vm.notification.type,"message":_vm.notification.message},on:{"notificationStatus":_vm.changeNotificationStatus}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }