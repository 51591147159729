<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon size="24" color="red lighthen-1" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
      </template>
      <v-card color="#071c33" dark>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-end align-center">
                  <v-icon size="24px" class="pointer" @click="dialog = false">mdi-close</v-icon>
                </div>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                  <h2 class="white--text">Você deseja realmente excluir a música "{{nameMusic}}"?</h2>
              </v-col>
              <v-col cols="12" class="d-flex justify-center mt-5">
                   <v-btn
                        v-if="!deleteConfirm"
                        color="#ef5350"
                        outlined
                        class="mb-4"
                        @click="deleteConfirm = true"
                        dark
                    >
                        <span>Excluir música</span>
                    </v-btn>
                    <v-btn v-else color="#ef5350" class="mb-4" @click="$emit('removeMusic', index)" dark>
                        <span>CONFIRMAR EXCLUSÃO</span>
                    </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    props: {
        nameMusic: {
            type: String,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        deleteConfirm: false,
        loading: false,
        dialog: false
    })
};
</script>

<style scoped>
    .submit-button {
        width: 150px !important;
    }
</style>