<template>
    <div>
        <app-profile-header :profileName="profileName" :profileType="profileType" :options="true" :idLabel="idLabelDecode"></app-profile-header>
        <app-list-artists :idLabel="idLabel"></app-list-artists>
    </div>
</template>

<script>
// import ProfileHeader from "../components/ProfileHeader"
import AlbumTrackData from '../components/AlbumTrackData.vue'


export default {
    components: {
        // 'app-profile-header': ProfileHeader,
        'app-list-artists': AlbumTrackData
    },
    created() {
        this.profileName = localStorage.tp_profile == 'Label' ? localStorage.no_profile : this.$route.params.name;
        this.idLabel = this.$route.params.id;
        const labelDecode = JSON.parse(atob(this.$route.params.id));
        this.idLabelDecode = labelDecode.id_label;
    },
   
}
</script>

<style>

</style>